import { useTranslation } from 'react-i18next';
import { useTripDesigner } from '../../../../hooks';
import React from 'react';
import moment from 'moment/moment';
import { getHotels } from '../../../../api/hotels';
import KoobModal from '../../../../components/Koob/KoobModal';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import ComposeHotelCard from './ComposeHotelCard';
import KoobPlaceholder from '../../../../components/Koob/KoobPlaceholder';
import { useAsync } from 'react-async';
import { isEqual } from 'lodash';
import { KoobParagraph } from '@koob/ui';

export default function ComposeDayHotelSuggestedModal({ open, close, addHotel, hotelIds, isIncluded, date }) {
  const { t } = useTranslation('tripDesigner');
  const { tripFilters } = useTripDesigner();

  const roomsComposition = tripFilters?.composition?.roomsComposition ?? [{...tripFilters?.composition}];

  const filters = {
    dmcId: tripFilters?.dmcId?.value ? Number(tripFilters?.dmcId?.value) : undefined,
    cities: [],
    countries: [],
    kinds: [],
    regions: [],
    styles: [],
    sustainability: null,
    startAt: !isIncluded
      ? moment(date).format('YYYY-MM-DD')
      : undefined,
    endAt: !isIncluded
      ? moment(date).add(1, 'day').format('YYYY-MM-DD')
      : undefined,
    hotels: hotelIds,
    rooms: roomsComposition.map(room => ({
      ...room,
      firstAdultNationality: room?.firstAdultNationality?.value ?? ''
    }))
  }

  const { data, reload, isLoading } = useAsync({
    promiseFn: getHotels,
    filters,
    skip: !hotelIds?.length || !open,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters)
        || !isEqual(props.skip, prevProps.skip);
    },
    onResolve: response => {
      if (response?.data?.state === 'inProgress') {
        setTimeout(() => reload(), 3000);
      }
    },
    onReject: () => {
      setTimeout(() => reload(), 5000);
    },
  });

  const hotels = data?.data?.data ?? [];

  const loading = isLoading || data?.data?.state === 'inProgress';

  return (
    <KoobModal
      open={open}
      setOpen={close}
      widthClass="max-w-7xl"
    >
      <div className="text-center">
        <KoobTitle>
          {isIncluded
            ? t('compose.exploreIncludedHotels')
            : t('compose.exploreSuggestedHotels')}
        </KoobTitle>

        {isIncluded && (
          <KoobParagraph>
            {t('compose.exploreIncludedHotelsDescription')}
          </KoobParagraph>
        )}

        <div className="mt-5 grid grid-cols-3 gap-5">
          {!loading && hotels.length === 0 && (
            <div className="col-span-3 text-center text-gray-500">
              {isIncluded
                ? t('compose.noSuggestedHotels')
                : t('compose.noIncludedHotels')}
            </div>
          )}

          {hotels?.map(hotel => (
            <ComposeHotelCard
              key={hotel.id}
              hotelId={hotel.id}
              addHotel={addHotel}
              hotelWithPrice={hotel}
              isForTrip={true}
              isIncluded={isIncluded}
            />
          ))}

          {loading && (
            <>
              <KoobPlaceholder className="w-full h-96 rounded-xl"/>
              <KoobPlaceholder className="w-full h-96 rounded-xl"/>
              <KoobPlaceholder className="w-full h-96 rounded-xl"/>
            </>
          )}
        </div>
      </div>
    </KoobModal>
  )
}
