import { useTranslation } from 'react-i18next';
import KoobAccordeonBlocComponent from "../../../Koob/KoobAccordeonBlocComponent";
import styled, {css} from "styled-components";
import {formatCurrency, formatDateWithTimezone} from "../../../../utils";
import {round} from "lodash";
import RoomTags from "../../../Booking/RoomTags";
import { Fragment } from 'react';

const OptionWrapper = styled.div`
  position: absolute;
  height: 36px;
  width: 124px;
  background-color: rgba(0, 0, 0, 0.06);
  top: -15px;
  color: rgba(0, 0, 0, 0.5) !important;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(0.35)}
    ${({ theme }) => theme.spacing(0.3)};
  ${props =>
  props.greenOption &&
  css`
      background-color: #eef8f2;
      color: ${({ theme }) => theme.primary} !important;
    `}

  ${props =>
  props.orangeOption &&
  css`
      background-color: #ffe3e3;
      color: #ea5d5d !important;
      width: 154px;
    `}
`;

const CancellationPolicies = ({ rooms, bedField, showNameAndBorder = true }) => {
  const { t } = useTranslation('booking');
  const accordeonDetailsHotelFees = (room) => {
    return ([
      {
        description: { displayName: t('hotelFees')},
        content: room[bedField].hotelFees.map((fee)=>{
          return (`${fee.text} : ${fee.amount} ${fee.currency}<br>`)
        }).join('')
      }
    ])
  }
  const accordeonDetailsContractingConditions = (room) => {
    return ([
      {
        description: { displayName: t('contractingConditions')},
        content: `${room[bedField].cancelConditions.map((cc)=>{
          return (`${t('room.from')}
          "${formatDateWithTimezone(cc.startAt)}" ${t('room.to')}${' '}
          "${formatDateWithTimezone(cc.endAt)}"${', '}
          ${t('room.cancellationFees')} ${"= "} ${formatCurrency({amount: round(cc.deduction, 2),currency: room[bedField].currency})}<br><br>`)
        }).join('')}${room[bedField].contractingConditions !== null ? room[bedField].contractingConditions : ""}`
      }
    ])
  }
  const accordeonDetailsCheckInInstructions = (room) => {
    return ([
      {
        description: { displayName: t('checkInInstructions')},
        content: room[bedField].checkInInstructions
      }
    ])
  }

  return (
    <>
      {rooms.map((room) => {
        return (
          <Fragment key={room?.id}>
            <div
              className={[
                'p-5',
                showNameAndBorder ? 'border rounded-lg' : '',
              ].join(' ')}
            >
              <div className="flex items-stretch justify-between">
                {showNameAndBorder && (
                  <div className="text-xl font-semibold">
                    {room?.name ?? ''}{' '}
                    {room[bedField]?.name ? `(${room[bedField]?.name})` : ''}
                  </div>
                )}
                
                <div className="flex items-center space-x-2 whitespace-nowrap self-start">
                  <RoomTags room={room} />
                </div>

              </div>

              {(room[bedField].contractingConditions ||
                (room[bedField].cancelConditions &&
                  room[bedField].cancelConditions.length > 0)) && (
                <><>
                  {(room[bedField].tags ?? []).indexOf('room_refundable') !==
                    -1 && (
                      <OptionWrapper greenOption>
                        {t('room.room_refundable')}
                      </OptionWrapper>
                    )}
                </><>
                  {(room[bedField].tags ?? []).indexOf('room_non_refundable') !==
                    -1 && (
                      <OptionWrapper orangeOption>
                        {t('room.room_non_refundable')}
                      </OptionWrapper>
                    )}

                </><KoobAccordeonBlocComponent accordeonDetail={accordeonDetailsContractingConditions(room)}
                                               translation={'hotel'}
                                               variant={"booking"}/>
                </>
              )}
              {room[bedField].hotelFees && (
                <KoobAccordeonBlocComponent accordeonDetail={accordeonDetailsHotelFees(room)}
                                            translation={'hotel'}
                                            variant={"booking"}/>
              )}
              {room[bedField].checkInInstructions && (
                <KoobAccordeonBlocComponent accordeonDetail={accordeonDetailsCheckInInstructions(room)}
                                            translation={'hotel'}
                                            variant={"booking"}/>
              )}
            </div>
          </Fragment>
        )
      })}
    </>
  );
};

export default CancellationPolicies;
