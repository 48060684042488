export default function SearchResultTitle({ title, query }) {
  if (!query) {
    return title;
  }

  const parts = title.split(new RegExp(`(${query})`, 'gi'));

  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <mark key={i} className="bg-yellow-200 rounded text-gray-900">{part}</mark>
        ) : (
          part
        )
      )}
    </>
  );
}
