import { Button, Stack } from '@koob/margaret';
import { Modal, Tooltip } from 'components/index';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Text } from 'ui';
import { DatePicker, DateRangePicker } from '../Fields';
import { IcOrganization } from '../icons';
import { MdAddCircleOutline, MdClose } from 'react-icons/md';
import { times } from 'lodash';
import { useState, useCallback } from 'react';
import Stepper from 'components/Stepper';
import SearchableSelect from 'components/Fields/SearchableSelectLocal';
import { useBooking } from 'hooks';
import { formatDate } from 'utils';
import { parse } from 'date-fns';
import { ChildrenBookingRoomsCompositionModal } from './ChildrenBookingCompositionModal';

const RoomBox = styled(Stack).attrs({
  direction: 'column',
  alignX: 'flex-start',
  gutterSize: 0.5,
})`
  padding: ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 30px;
`;

const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.darkSeparator};
  padding: 0 ${({ theme }) => theme.spacing(0.375)};
`;

const AddRoomButton = styled(Button)`
  border-radius:30px;
`

const StackLabel = styled(Stack)`
  width:150px;
`

const BirthdayGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  height: 100%;
  margin-top:20px;
`

const  GuestStepper = ({ title, hint, onChange, value, id, max, allowDecrement = true }) => (
  <Stack gutterSize={2}>
    <StackLabel direction="column">
      <Text type="bodyLarge" fontWeight="700" color="text">
        {title}
      </Text>
      <Text kind="legend">{hint}</Text>
    </StackLabel>
    <Stepper
      onChange={onChange}
      value={value}
      max={max}
      allowDecrement={allowDecrement}
      identifier={id}
    />
  </Stack>
)

const PeriodField = () => {
  const theme = useTheme();
  const { filters, setFilters } = useBooking();
  const updateDates = useCallback(
    (start, end) => {
      setFilters(current => ({
        ...current,
        startAt: start,
        endAt: end,
      }));
    },
    [setFilters],
  );
  const start = filters?.startAt ? parse(filters.startAt, 'yyyy-MM-dd', new Date()) : undefined;
  let end = filters?.endAt ? parse(filters.endAt, 'yyyy-MM-dd', new Date()) : undefined;

  if (start.getTime() === end.getTime()) {
    end.setDate(end.getDate() + 1);
  }
  return (
    <DateRangePicker
      minDate={new Date(filters?.minDate ?? '')}
      maxDate={new Date(filters?.maxDate)}
      wrapperStyle={{
        width: 265,
        '--range-picker-width': `300px`,
        '--range-picker-padding': theme.spacing(0.125),
      }}
      value={[start, end]}
      isClearable={false}
      onChange={value => {
        const start = formatDate(value?.[0], 'yyyy-MM-dd');
        const end = formatDate(value?.[1], 'yyyy-MM-dd');
        updateDates(start, end);
      }}
    />
  )
};

const calculateTotalsCompositionsPax = compositions => {
  return (compositions ?? []).reduce(
    (totals, composition) => {
      totals.totalChildrenPax += composition.children;
      totals.totalAdultsPax += composition.adults;
      totals.totalDefinedChildrenBirthdates +=
        composition.children &&
        (composition.childrenBirthdates?.filter(date => !!date).length ?? 0);

      return totals;
    },
    {
      totalChildrenPax: 0,
      totalAdultsPax: 0,
      totalDefinedChildrenBirthdates: 0,
    },
  );
};

const calculateTotalPax = compositions => {
  return (compositions ?? []).reduce((total, composition) => {
    return total + composition.adults + composition.children;
  }, 0);
};

const initialRoomValue = {
  adults: 0,
  children: 0,
  childrenBirthdates: [],
  count: 1,
  firstAdultNationality: {},
};

const BookingRoomsCompositionModal = ({
  onRequestClose,
  onSave,
  initialValue = [initialRoomValue],
  translationKey = 'hotel',
  multiselect,
  removeChildren = true,
  maxAdults,
  maxChildren,
  requireChildrenBirthdates = true,
  hasNationalityField,
  hasPeriodField,
  parentChildrenBirthDates = [],
  canHaveChildCompositions = false,
  isChildrenComposition = false,
}) => {
  const { t } = useTranslation(translationKey, {
    keyPrefix: 'search.bookingRoomsCompositionModal',
  });
  const { nationalities } = useBooking();
  const [value, setValue] = useState(initialValue);
  const [showCompositionModal, setShowCompositionModal] = useState(false);

  const hasAtLeastOnePerson = value.some(
    item =>
      item.adults > 0 ||
      (item.children > 0 && item.childrenBirthdates.length > 0),
  );
  value.map(room => {
    if (
      room?.children < room?.childrenBirthdates?.length
    ) {
      room?.childrenBirthdates.pop();
    }
    room.count = room.adults + room.children;
    return room;
  });

  const isCompositionValidForSave = () => {
    const hasChildrenComposition = value.some(
      composition => composition?.roomsComposition,
    );
    const areAllCompositionsValid = value.every(
      composition => composition.adults || composition.children,
    );

    if (!isChildrenComposition && !hasChildrenComposition) {
      return true;
    }

    const totalMaxPax = (maxAdults ?? 0) + (maxChildren ?? 0);
    const totalPax = calculateTotalPax(value);

    const { totalChildrenPax, totalAdultsPax, totalDefinedChildrenBirthdates } =
      calculateTotalsCompositionsPax(value);

    if (!isChildrenComposition && hasChildrenComposition) {
      const {
        totalChildrenPax: totalChildrenPaxChildrenComposition,
        totalAdultsPax: totalAdultsPaxChildrenComposition,
      } = calculateTotalsCompositionsPax(value[0].roomsComposition);

      return (
        totalAdultsPax === totalAdultsPaxChildrenComposition &&
        totalChildrenPax === totalChildrenPaxChildrenComposition
      );
    }

    return (
      areAllCompositionsValid &&
      value?.length <= totalMaxPax &&
      totalPax <= totalMaxPax &&
      totalAdultsPax === maxAdults &&
      totalChildrenPax === maxChildren &&
      totalDefinedChildrenBirthdates === maxChildren
    );
  };
  const isParentChildrenBirthdateDefined = parentChildrenBirthDates.length > 0;
  const hasIncompleteChildrenBirthdates = value?.some(
    room => room?.childrenBirthdates?.length < room?.children,
  );

  const formatBirthDate = birthdate => {
    return {
      label: formatDate(birthdate, 'dd-MM-yyyy'),
      value: birthdate,
    };
  };

  const handleChangeChildrenBirthdates = (
    newBirthday,
    roomIndex,
    childIndex,
  ) => {
    setValue(
      value.map((room, index) => {
        if (index !== roomIndex) {
          return room;
        }
        return {
          ...room,
          childrenBirthdates: times(room?.children).map(index => {
            if (index !== childIndex) {
              return value?.[roomIndex]?.childrenBirthdates?.[index];
            }
            return newBirthday;
          }),
        };
      }),
    );
  };

  return (
    <>
      <Modal
        size="medium"
        isOpen
        title={t('title')}
        onRequestClose={onRequestClose}
        modalContentProps={{ padding: 1, textAlign: 'inherit' }}
        footer={
          <Stack size="full" alignX="flex-end">
            <Tooltip
              tip={t('childrenRoomsError')}
              position="top"
              color="purple"
              hasArrow={false}
              disabled={isCompositionValidForSave()}
            >
              <Button
                variant="primary"
                onClick={() => onSave(value)}
                disabled={
                  (requireChildrenBirthdates &&
                    hasIncompleteChildrenBirthdates) ||
                  !isCompositionValidForSave()
                }
                id="save-traveler"
              >
                {t('save')}
              </Button>
            </Tooltip>
          </Stack>
        }
      >
        <Stack
          direction="column"
          gutterSize={1}
          size="full"
          alignX="stretch"
          paddingTop={0.5}
          paddingBottom={0.5}
        >
          {hasPeriodField && <PeriodField />}
          {(value || []).map((room, roomIndex) => (
            <RoomBox gutterSize={0.5} key={roomIndex}>
              <Stack size="full" alignY="center" alignX="space-between">
                <Text type="h2">
                  <Stack alignY="center" gutterSize={0.5} color="text">
                    <IcOrganization />
                    <span>
                      {t('nthRoom', {
                        cardinal: roomIndex + 1,
                      })}
                    </span>
                  </Stack>
                </Text>
                <Stack alignX="center">
                  {canHaveChildCompositions && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setShowCompositionModal(true);
                      }}
                      disabled={
                        !hasAtLeastOnePerson ||
                        (requireChildrenBirthdates &&
                          hasIncompleteChildrenBirthdates)
                      }
                    >
                      {t('addRooms')}
                    </Button>
                  )}
                  {roomIndex !== 0 && (
                    <CloseButton
                      onClick={() =>
                        setValue(
                          value.filter((_, index) => index !== roomIndex),
                        )
                      }
                    >
                      <MdClose size={20} />
                    </CloseButton>
                  )}
                </Stack>
              </Stack>

              <Stack size="full" alignX="space-between">
                <GuestStepper
                  title={t('adults')}
                  hint={t('adultsHint')}
                  value={value?.[roomIndex]?.adults ?? 1}
                  onChange={adults =>
                    setValue(
                      value.map((room, index) => {
                        if (roomIndex !== index) {
                          return room;
                        }
                        return { ...room, adults };
                      }),
                    )
                  }
                  max={maxAdults ?? 20}
                  id={`adults-${roomIndex}`}
                />
              </Stack>

              <Stack size="full" alignX="space-between">
                <GuestStepper
                  title={t('children')}
                  hint={t('childrenHint')}
                  value={value?.[roomIndex]?.children ?? 0}
                  onChange={children =>
                    setValue(
                      value.map((room, index) => {
                        if (roomIndex !== index) {
                          return room;
                        }
                        return { ...room, children };
                      }),
                    )
                  }
                  max={maxChildren ?? 20}
                  allowDecrement={removeChildren}
                  id={`children-${roomIndex}`}
                />
              </Stack>

              {hasNationalityField && (
                <Stack direction="column" gutterSize={1}>
                  <Text type="bodyLarge" fontWeight="700" color="text">
                    {t('firstAdultNationality')}
                  </Text>
                  <SearchableSelect
                    datas={nationalities ?? []}
                    onChange={country => {
                      setValue(
                        value.map((room, index) => {
                          if (roomIndex !== index) {
                            return room;
                          }
                          return {
                            ...room,
                            firstAdultNationality: country,
                          };
                        }),
                      );
                    }}
                    value={value?.[roomIndex]?.firstAdultNationality}
                    renderOption={value => value?.label}
                    renderSelectedOption={value => value?.label}
                    wrapperStyle={{
                      marginLeft: 0,
                      minWidth: 300,
                    }}
                  />
                </Stack>
              )}

              {room?.children !== undefined && room?.children > 0 && (
                <span>{t('childBirthdates')}</span>
              )}
              <BirthdayGrid>
                {times(room?.children).map(childIndex => (
                  <div className="flex space-x-2" key={childIndex}>
                    <div
                      className={
                        !removeChildren &&
                        'opacity-50 pointer-events-none cursor-not-allowed'
                      }
                    >
                      {!isParentChildrenBirthdateDefined ? (
                        <DatePicker
                          rounded
                          value={
                            value?.[roomIndex]?.childrenBirthdates?.[childIndex]
                          }
                          onChange={newBirthday => {
                            handleChangeChildrenBirthdates(
                              newBirthday,
                              roomIndex,
                              childIndex,
                            );
                          }}
                          isClearable={false}
                        />
                      ) : (
                        <SearchableSelect
                          datas={
                            (parentChildrenBirthDates ?? []).map(birthdate =>
                              formatBirthDate(birthdate),
                            ) ?? []
                          }
                          onChange={birthdate => {
                            handleChangeChildrenBirthdates(
                              birthdate.value,
                              roomIndex,
                              childIndex,
                            );
                          }}
                          value={formatBirthDate(
                            value?.[roomIndex]?.childrenBirthdates?.[
                              childIndex
                            ],
                          )}
                          renderOption={value => value?.label}
                          renderSelectedOption={value => value?.label}
                          wrapperStyle={{
                            marginLeft: 0,
                            width: 200,
                          }}
                        />
                      )}
                    </div>
                    {!removeChildren && (
                      <Button
                        onClick={() => {
                          setValue(
                            value.map((room, index) => {

                              if (index !== roomIndex) {
                                return room;
                              }
                              return {
                                ...room,
                                childrenBirthdates:
                                  room?.childrenBirthdates.filter(
                                    (_, index) => index !== childIndex,
                                  ),
                                children: room?.children - 1,
                              };
                            }),
                          );
                        }}
                      >
                        <MdClose size={20} />
                      </Button>
                    )}
                  </div>
                ))}
              </BirthdayGrid>
            </RoomBox>
          ))}
          {multiselect && (
            <Stack gutterSize={1} size="full" alignX="space-between">
              <AddRoomButton
                variant="simple"
                icon={<MdAddCircleOutline size={20} />}
                onClick={() => setValue([...value, initialRoomValue])}
              >
                {t('addRoom')}
              </AddRoomButton>
            </Stack>
          )}
        </Stack>
      </Modal>

      {showCompositionModal && (
        <ChildrenBookingRoomsCompositionModal
          open={showCompositionModal}
          setOpen={setShowCompositionModal}
          value={value}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default BookingRoomsCompositionModal;
