import KoobTableCell from '../../../../components/Koob/KoobTableCell';
import moment from 'moment/moment';
import { formatCurrency } from '../../../../utils';
import ReceiptContentItemType from './ReceiptContentItemType';
import { useMemo } from 'react';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { useTranslation } from 'react-i18next';

export const receiptItemDateFormatter = (startAt, endAt) => {
  if (moment(startAt).isSame(endAt, 'day') || !endAt) {
    return moment(startAt).format('MMM DD, YYYY');
  }
  return `${moment(startAt).format('MMM DD')} — ${moment(endAt).format('MMM DD, YYYY')}`;
};

export default function ReceiptContentItem({ item, onRemove, setAllItems }) {
  const { t } = useTranslation('folders');
  const formattedDate = useMemo(() => {
    if (!item.startAt && !item.endAt) {
      return '';
    }
    return receiptItemDateFormatter(item.startAt, item.endAt);
  }, [item.startAt, item.endAt]);


  const handleMarkupItem = ({
    id,
    type,
    markup = item.markup,
    isMarkupPercent = item.isMarkupPercent,
  }) => {
    const startAt = item.startAt;
    const markupValue =  markup ?? 0;
    setAllItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.type === type && item.startAt === startAt
          ? {
              ...item,
              markup: markupValue,
              isMarkupPercent: isMarkupPercent,
              price: isMarkupPercent
                ? item.priceWithoutMarkup * (1 + markupValue / 100)
                : item.priceWithoutMarkup + (markupValue ?? 0),
            }
          : item
      )
    );
  };

  return (
    <tr>
      <KoobTableCell>
        <div className="whitespace-normal">
          <div className="flex space-x-3 items-center">
            <ReceiptContentItemType type={item.type}/>

            <div>
              <div className="font-medium text-base text-gray-600">
                {item.name}
              </div>
              <div>
                {item.location}
              </div>
            </div>

            {item.state === 'canceled' && (
              <KoobBadge
                color="red"
                size="sm"
              >
                {t('bookings.status.cancelled')}
              </KoobBadge>
            )}
          </div>
        </div>
      </KoobTableCell>
      <KoobTableCell>
        <div className="export-hidden flex items-center space-x-2">
          <input
            type="number"
            value={item?.markup}
            onChange={(e) => {
              const markup = Number(e.target.value)
              handleMarkupItem({
                id: item.id,
                type: item.type,
                markup: markup 
              });
            }}
            className="bg-white border border-gray-300 rounded-md w-1/2"
            placeholder="e.g. 10"
          />
          <div className="flex items-center space-x-2">
            <input
              checked={item.isMarkupPercent}
              type="checkbox"
              onChange={(event) => {
                handleMarkupItem({
                  id: item.id,
                  type: item.type,
                  isMarkupPercent: event.target.checked,
                });
              }}
            />
            <i className="fa-solid fa-percent"/>
          </div>
        </div>
      </KoobTableCell>
      <KoobTableCell>
        {formattedDate}
      </KoobTableCell>

      <KoobTableCell>
        {item.pax && (
          <>
            <i className="far fa-user mr-1"/>
            {item.pax}
          </>
        )}
      </KoobTableCell>

      {item.price !== undefined && (
        <KoobTableCell>
          <div
            className={[
              'text-right',
              item.state === 'canceled' ? 'line-through text-gray-500' : ''
            ].join(' ')}
          >
            {formatCurrency({
              amount: item.price,
              currency: item.currency
            })}
          </div>

          {item.state === 'canceled' && (
            <div className="text-right font-medium text-red-500">
              {formatCurrency({
                amount: item.cancellationFee,
                currency: item.currency
              })}
            </div>
          )}
        </KoobTableCell>
      )}
      {item.type === 'manual' && (
        <KoobTableCell>
          <div className="export-hidden ml-2" onClick={() => onRemove()}>
            <i className="fa-regular fa-trash"/>
          </div>
        </KoobTableCell>
      )}
    </tr>
  )
}
