import KoobTable from '../../../../components/Koob/KoobTable';
import ReceiptContentItem from './ReceiptContentItem';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import { useMemo, useState } from 'react';
import ReceiptContentManualItem from './ReceiptContentManualItem';
import ReceiptContentTotal from './ReceiptContentTotal';
import ReceiptContentMargin from './ReceiptContentMargin';
import { useTranslation } from 'react-i18next';

export default function ReceiptContent({ items }) {
  const { t } = useTranslation('trips');

  const [allItems, setAllItems] = useState([]);
  const [prevItems, setPrevItems] = useState([]);

  const heads = [
    { label: t('receipt.bookedItems.columnItem') },
    { label: t('receipt.bookedItems.markup') , export: false },
    { label: t('receipt.bookedItems.columnDates')},
    { label: t('receipt.bookedItems.columnPax') },
    { label: t('receipt.bookedItems.columnTotal'), align: 'right' },
    { label: '', align: 'right' },
  ];
  if (prevItems.length !== items.length) {
    const newItems = items.map((item) => ({
      ...item,
      priceWithoutMarkup: Number(item.price),
    }));
    setPrevItems(newItems);
    setAllItems(newItems);
  } 

  const grandTotalPrice = useMemo(() => {
    return allItems
      ?.filter((item) => !!item.price && (item.state !== 'canceled' || item.cancellationFee))
      ?.reduce((acc, item) => acc + parseFloat(item.cancellationFee >= 0 ? item.cancellationFee : item.price), 0);
  }, [allItems]);
 
  const grandTotalCurrency = items?.[0]?.currency;

  return (
    <div>
      <KoobTitle>
        {t('receipt.bookedItems.title')}
      </KoobTitle>

      <KoobTable heads={heads}>
        {allItems?.map((item, index) => (
          <ReceiptContentItem
            key={index}
            onRemove={()=> {
              setAllItems((currentItems) =>
                currentItems.filter(({ id }) => id !== item.id)
              );
            }}
            setAllItems={setAllItems}
            item={item}
          />
        ))}
      </KoobTable>

      <ReceiptContentTotal
        price={grandTotalPrice}
        currency={grandTotalCurrency}
      />

      <ReceiptContentManualItem
        setManualItems={setAllItems}
        currency={grandTotalCurrency}
      />

      <ReceiptContentMargin
        setAllItems={setAllItems}
      />
    </div>
  )
}
