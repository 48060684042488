import { cva } from 'class-variance-authority';

export default function SearchInputField({ label, types, rounded = 'full', value, onChange, clearable, setIsFocused }) {
  const input = cva([
    'block w-full border-0 py-2.5 pl-9 text-gray-900 placeholder:text-gray-400',
    'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-k-orange focus:outline-none',
  ], {
    variants: {
      rounded: {
        sm: 'rounded-sm',
        md: 'rounded-md',
        full: 'rounded-full',
      },
      clearable: {
        true: 'pr-9',
        false: 'pr-3',
      }
    }
  });

  const inputWrapper = cva([
    'relative shadow-sm',
  ], {
    variants: {
      rounded: {
        sm: 'rounded-sm',
        md: 'rounded-md',
        full: 'rounded-full',
      },
      clearable: {
        true: 'cursor-pointer bg-red-500'
      }
    }
  });

  const placeholder = `Search ${types.join(', ')}`;

  return (
    <div>
      {label && (
        <label className="mb-2 block text-sm/6 font-medium text-gray-900">
          {label}
        </label>
      )}

      <div
        className={inputWrapper({ rounded, clearable })}
        onClick={() => {
          if (clearable) {
            onChange('');
          }
        }}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
          <i className="fa fa-search text-gray-400" />
        </div>

        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className={input({ rounded, clearable })}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={clearable}
        />

        {clearable && (
          <div className="absolute border-none inset-y-0 right-0 flex items-center pr-3.5">
            <i className="fa fa-times text-gray-400" />
          </div>
        )}
      </div>
    </div>
  )
}
