import { useState } from 'react';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import ReceiptHiddenSection from './ReceiptHiddenSection';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import { useTranslation } from 'react-i18next';


export default function ReceiptContentMargin({ setAllItems }) {
  const { t } = useTranslation('trips');
  const [markup, setMarkup] = useState(0);
  const [isMarkupPercent, setIsMarkupPercent] = useState(true);

  const handleGlobalMarkup = (markup, isPercent = isMarkupPercent) => {
    const markupValue =  markup ?? 0;
    setAllItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        markup: markupValue,
        isMarkupPercent: isPercent,
        price: isPercent
          ? item.priceWithoutMarkup * (1 + markupValue / 100)
          : item.priceWithoutMarkup + markupValue,
      }))
    );
  }

  return (
    <ReceiptHiddenSection className="mt-3">
      <KoobTitle size="md">
        <div className="flex space-x-3 items-center">
          <div>{t('receipt.markup.title')}</div>
          <KoobBadge>{t('receipt.optional')}</KoobBadge>
        </div>
      </KoobTitle>

      <div className="mt-3 flex justify-between">
        <KoobParagraph size="sm">
          {t('receipt.markup.description')}
        </KoobParagraph>
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={isMarkupPercent}
            onChange={event => {
              const isChecked = event.target.checked;
              setIsMarkupPercent(isChecked);
              handleGlobalMarkup(markup, isChecked);
            }}
          />
          <p className="text-sm">{t('receipt.markup.usePercentMargin')}</p>
        </div>
      </div>

      <div className="my-3">
        <input
          value={markup}
          type="number"
          onChange={e => {
            const value = Number(e.target.value);
            setMarkup(value);
            handleGlobalMarkup(value, isMarkupPercent);
          }}
          className="w-full bg-white border border-gray-300 rounded-md p-2"
          placeholder="e.g. 10"
        />
      </div>
    </ReceiptHiddenSection>
  );
}
