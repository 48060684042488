import React, { useState } from 'react';
import { RoomCard } from './RoomCards';
import CheckoutHotelRoom from '@koob/checkout/src/components/CheckoutHotelRoom';

export default function RoomItem({
  possibility,
  selectedPossibilityIndex,
  handleSelect,
  isSelected,
  isRoomSelected,
  filters,
  deleteRoom,
  promotions,
  selectedRoomsFromPromotionSelect,
  setSelectedPromotionSupplements,
  selectedPromotionSupplements,
  selectedRoomsCount
}) {
  const [currentBedIndex, setCurrentBedIndex] = useState(0);

  const currentPossibility = selectedPossibilityIndex === undefined
    ? possibility
    : possibility?.variants?.find(variant => variant.originalIndex === selectedPossibilityIndex) ?? possibility;

  const currentBed = possibility?.bed ?? currentPossibility?.bedChoices[currentBedIndex];

  const travelersCount = Math.max(
    filters?.rooms
      ?.reduce((acc, room) => acc + room.adults + room.children, 0)
    , 1
  );

  return (
    <CheckoutHotelRoom
      scope="to"
      room={currentPossibility}
      parentRoom={possibility}
      selectedRoomIndex={selectedPossibilityIndex}
      currentBed={currentBed}
      price={{
        total: currentBed?.totalPriceWithPromo ?? 0,
        currency: currentBed?.currency,
        travelersCount,
      }}
      isSelected={isSelected}
      onSelect={(index) => handleSelect(index)}
      id={`${currentPossibility?.name?.toLowerCase().split(' ').join('-')}-${selectedRoomsCount}`}
    >
      <RoomCard
        possibility={currentPossibility}
        currentBed={currentBed}
        currentBedIndex={currentBedIndex}
        changeSelectedBed={(bedIndex) => {
          setCurrentBedIndex(bedIndex);
          handleSelect(possibility?.originalIndex, bedIndex);
        }}
        isSelected={isSelected}
        isRoomSelected={isRoomSelected}
        deleteRoom={deleteRoom}
        promotions={promotions}
        selectedRoomsFromPromotionSelect={selectedRoomsFromPromotionSelect}
        setSelectedPromotionSupplements={setSelectedPromotionSupplements}
        selectedPromotionSupplements={selectedPromotionSupplements}
      />
    </CheckoutHotelRoom>
  );
}
