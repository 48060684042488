import { useAuth } from '../hooks';
import { useTranslation } from 'react-i18next';
import { revokeToken } from '../api/auth';

export default function UserImpersonationAlert() {
  const { logout, impersonating, setImpersonationToken } = useAuth();
  const { t } = useTranslation('impersonation');

  const stopImpersonating = async () => {
    if (localStorage.getItem('accessToken') === localStorage.getItem('impersonationToken')) {
      logout();
    } else {
      await revokeToken(true);
      setImpersonationToken('');
      window.location.href = '/';
    }
  };

  return impersonating ? (
    <div className="export-hidden fixed bottom-0 w-full z-50">
      <div className="flex justify-center items-center gap-x-6 bg-red-600 px-6 py-2.5">
        <p className="text-sm font-medium leading-6 text-white">{t('title')}</p>
        <button
          onClick={() => stopImpersonating()}
          type="button"
          className="-m-3 px-3 py-1 text-sm bg-white rounded-md focus-visible:outline-offset-[-4px]"
        >
          Stop Impersonating
        </button>
      </div>
    </div>
  ) : null;
}
