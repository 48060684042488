import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getExperience } from '../../../api/experiences';
import KoobBadge from '../../../components/Koob/KoobBadge';
import { Tooltip } from '../../../components';
import ExperienceExtraComposition from './ExperienceExtraComposition';
import { formatCurrency } from '../../../utils';
import KoobButton from '../../../components/Koob/KoobButton';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import React, { useState } from 'react';
import ExperienceExtrasModal from './ExperienceExtrasModal';
import BookingRoomsCompositionModal from '../../../components/Hotel/BookingRoomsCompositionModal';
import { formatExtraPrice, getPricingForCompositionExtra } from '../utils';
import { ExtraRequirementEnum, ExtraScopeEnum } from '@koob/enums';
import { getImageUrl } from '@koob/utils';

const EXTRA_COMPATIBILITY_COLOR = {
  bestMatch: 'green',
  neutral: 'blue',
  incompatible: 'red',
};

export default function ExperienceExtrasModalListItem({
  extraId,
  extraType,
  extraScope,
  compatibility,
  parentExtra,
  selectedExtras,
  setSelectedExtras,
  dayIndex,
  prices,
  currency,
  showPaxUnmetWarning,
  experience,
  experienceComposition,
  source,
  disabled,
  readOnly = false,
}) {
  const { t } = useTranslation('experiences');

  const [showExtrasModal, setShowExtrasModal] = useState(false);
  const [showCompositionModal, setShowCompositionModal] = useState(false);
  const [skipChildrenExtra, setSkipChildrenExtra] = useState(
    selectedExtras?.find(extra => extra.id === extraId)?.skipChildrenExtra ??
    false,
  );

  const { data, isLoading } = useAsync({
    promiseFn: getExperience,
    experienceId: extraId
  });
  const extra = data?.data;

  const experienceExtra = extraScope === ExtraScopeEnum.GLOBAL
    ? experience?.extras?.find(ex => ex.id === extraId)
    : experience?.programs?.map(program =>
      program.suggestedExperiences?.find(ex => ex.id === extraId),
    )?.[0];

  const childrenExtras = [
    ...(experienceExtra?.required?.map(ex => ({ ...ex, type: ExtraRequirementEnum.REQUIRED })) ??
      []),
    ...(experienceExtra?.suggested?.map(ex => ({ ...ex, type: ExtraRequirementEnum.SUGGESTED })) ??
      []),
  ];

  const selectedChildrenExtras = selectedExtras.filter(
    extra => extra.parentExtraId === extraId,
  );
  const selectedExtra = selectedExtras.find(
    extra =>
      extra.id === extraId &&
      extra.experienceId === experience?.id &&
      extra.dayIndex === dayIndex &&
      extra.scope === extraScope
  );

  const initialComposition = {
    adults: selectedExtra?.adults ?? experienceComposition?.adults,
    adultCount: selectedExtra?.adultCount ?? experienceComposition?.adultCount,
    children: selectedExtra?.children ?? experienceComposition?.children,
    childrenBirthdates: selectedExtra?.childrenBirthdates ?? experienceComposition?.childrenBirthdates,
    count: selectedExtra?.count ?? experienceComposition?.count,
  };

  const [composition, setComposition] = useState(initialComposition);

  const applicablePrices = prices?.find(
    price => price.id === extraId,
  )?.pricings;


  const extraPrice = getPricingForCompositionExtra({
    composition: composition,
    pricings: applicablePrices,
  });

  const priceObj = formatExtraPrice(composition, extraPrice);

  const hasPrice = priceObj && (priceObj?.price !== 0) && priceObj?.currency !== 'mismatch';

  const totalPriceWithPromo =
    (priceObj?.price ?? 0) +
    selectedChildrenExtras.reduce((acc, extra) => {
      const pax = (extra?.adults + extra?.childrenBirthdates?.length);
      if (priceObj?.perPax && !extra.perPax) {
        return acc + (extra?.price / pax);
      }

      if (!priceObj?.perPax && extra.perPax) {
        return acc + extra?.price * pax;
      }

      return (
        acc + extra?.price
      );
    }, 0);

  const totalPriceWithoutPromo =
    (priceObj?.priceWithoutPromo ?? 0) +
    selectedChildrenExtras.reduce((acc, extra) => {
      const pax = (extra?.adults + extra?.childrenBirthdates?.length);

      if (priceObj?.perPax && !extra.perPax) {
        return acc + (extra?.priceWithoutPromo / pax);
      }

      if (!priceObj?.perPax && extra.perPax) {
        return acc + extra?.priceWithoutPromo * pax;
      }

      return (
        acc +
        extra?.priceWithoutPromo
      );
    }, 0);

  const hasPromo = totalPriceWithoutPromo !== totalPriceWithPromo;

  if (extra && priceObj) {
    extra.perPax = !!priceObj?.perPax;
  }

  const addExtra = (hasCompletedChildrenExtra = false, extraComposition = null) => {
    if (extraComposition) {
      setComposition(extraComposition);
    }

    if (extra?.perPax && !parentExtra && !extraComposition) {
      setShowCompositionModal(true);
      return;
    }

    if (
      !skipChildrenExtra &&
      !hasCompletedChildrenExtra &&
      childrenExtras.length
    ) {
      setShowExtrasModal(true);
      return;
    }

    setSelectedExtras(prev => [
      ...prev.filter(extra =>
        extra.id !== extraId ||
        extra.experienceId !== experience?.id ||
        extra.dayIndex !== dayIndex ||
        extra.scope !== extraScope,
      ),
      {
        ...extra,
        id: extraId,
        experienceId: experience?.id,
        parentExtraId: parentExtra?.id,
        dayIndex: dayIndex,
        ...(extraComposition ?? initialComposition),
        adultCount: (extraComposition?.adults ?? initialComposition?.adults),
        ...priceObj,
        currency: currency,
        skipChildrenExtra,
        type: extraType,
        scope: extraScope,
      },
    ]);

    if (parentExtra) {
      setShowExtrasModal(false);
    }
  };

  const removeExtra = () => {
    setSkipChildrenExtra(null);
    setSelectedExtras(
      selectedExtras.filter(extra =>
        extra.parentExtraId !== extraId && (
          extra.id !== extraId ||
          extra.experienceId !== experience?.id ||
          extra.dayIndex !== dayIndex ||
          extra.scope !== extraScope
        ),
      ),
    );
  };

  return !experience?.state || experience?.state === 'available' ? (
    <>
      <div className="relative bg-white border shadow-k rounded-lg p-5">
        {!isLoading ? (
          <div>
            {extraType && (
              <div className="absolute -top-4 left-0 w-full flex justify-center">
                <KoobBadge
                  color={extraType === ExtraRequirementEnum.REQUIRED ? "orange" : 'blue'}
                  rounded="full"
                >
                  {extraType === ExtraRequirementEnum.REQUIRED
                    ? t('extras.required')
                    : t('extras.suggested')}
                </KoobBadge>
              </div>
            )}

            <div className="flex space-x-3">
              <img
                src={getImageUrl(extra) ?? '/img/hotel-image-placeholder.png'}
                onError={ev =>
                  (
                    ev.target.src = '/img/hotel-image-placeholder.png'
                  )
                }
                className="w-20 h-20 rounded-lg object-cover"
                alt=""
              />

              <div className="flex w-full justify-between">
                <div className="mb-3 text-lg font-medium w-full h-20 overflow-y-auto">
                  {extra?.name}
                </div>

                <div>
                  {compatibility && (
                    <Tooltip
                      tip={t(`extras.tooltip.${compatibility}`)}
                      color="purple"
                      hasArrow={false}
                      position="left-top"
                    >
                      <div className={`text-${EXTRA_COMPATIBILITY_COLOR[compatibility]}-600`}>
                        <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                          <path
                            d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zm82.3 195.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full flex justify-between items-center transition">
              <a
                className="underline text-sm text-gray-500 hover:text-gray-600"
                href={`/experiences/${extra?.id}${source ? `?source=${source}` : null}`}
                target="_blank"
                rel="noreferrer"
              >
                {t('extras.learnMore')}
              </a>

              {selectedExtra && childrenExtras?.length ? (
                <div
                  className="px-3 py-1 bg-gray-500 hover:bg-gray-600 rounded-full cursor-pointer"
                  onClick={() => setShowExtrasModal(true)}
                >
                  <div className="text-sm font-medium text-white">
                    <i className="far fa-layer-plus mr-1"/>
                    {t('extras.editSecondaryOptions')}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="my-2 h-20 bg-gray-100 rounded px-4 py-2 overflow-y-auto">
              <div
                className="text-sm text-gray-500 w-full truncate"
                dangerouslySetInnerHTML={{ __html: experience?.description }}
              />
            </div>

            {hasPrice ? (
              <div>
                <div className="mb-2 bg-gray-100 rounded py-2 px-4">
                  <div className="flex justify-between">
                    <ExperienceExtraComposition
                      adults={composition?.adults}
                      childs={composition?.childrenBirthdates.length}
                    />

                    <div
                      className={[
                        'text-lg text-center font-medium',
                        !!composition
                          ? 'text-orange-500'
                          : 'text-gray-500',
                      ].join(' ')}
                    >
                      {priceObj?.price ? (
                        <div className="flex space-x-2 items-center">
                          {hasPromo && (
                            <div className="text-xs line-through text-gray-500">
                              {formatCurrency({
                                amount: totalPriceWithoutPromo,
                                currency,
                              })}{' '}
                              {priceObj?.perPax && (
                                <span>&nbsp;&frasl;&nbsp; {t('basket.pax')}</span>
                              )}
                            </div>
                          )}

                          <div>
                            {formatCurrency({
                              amount: totalPriceWithPromo,
                              currency,
                            })}{' '}
                            {priceObj?.perPax && (
                              <span>&nbsp;&frasl;&nbsp; {t('basket.pax')}</span>
                            )}
                          </div>
                        </div>
                      ) : showPaxUnmetWarning ? (
                        <div className="text-base">
                          {t('extras.paxRequirementsUnmet')}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {!selectedExtra ? (
                  <KoobButton
                    onClick={() => addExtra()}
                    disabled={disabled || compatibility === 'incompatible' || readOnly}
                  >
                    {t('extras.add')}
                  </KoobButton>
                ) : (
                  <KoobButton
                    onClick={() => removeExtra()}
                    color="green"
                    disabled={readOnly}
                  >
                    {t('extras.remove')}
                  </KoobButton>
                )}
              </div>
            ) : (
              <div className="w-full bg-orange-100 px-4 py-2 rounded">
                <div className="text-sm text-orange-400">
                  {t('extras.notAvailableForPeriod')}
                </div>
              </div>
            )}

            {childrenExtras?.length ? (
              <ExperienceExtrasModal
                open={showExtrasModal}
                setOpen={setShowExtrasModal}
                experience={experience}
                experienceComposition={composition}
                parentExtra={extra}
                extrasScope={extraScope}
                extras={childrenExtras}
                selectedExtras={selectedExtras}
                setSelectedExtras={setSelectedExtras}
                prices={prices}
                currency={currency}
                onContinue={() => addExtra(true, composition)}
                skipChildrenExtra={skipChildrenExtra}
                setSkipChildrenExtra={setSkipChildrenExtra}
                dayIndex={dayIndex}
                source={source}
                readOnly={readOnly}
              />
            ) : null}
          </div>
        ) : (
          <div>
            <div className="flex space-x-3">
              <div>
                <KoobPlaceholder className="w-20 h-20 rounded-lg"/>
              </div>

              <div className="w-full">
                <KoobPlaceholder className="w-full h-16 rounded"/>
              </div>
            </div>

            <KoobPlaceholder className="mt-3 w-28 h-5 rounded"/>

            <KoobPlaceholder className="my-2 w-full h-16 rounded-lg"/>

            {hasPrice && (
              <>
                <KoobPlaceholder className="my-2 w-full h-6 rounded-lg"/>

                <KoobPlaceholder className="w-40 h-10 rounded-lg"/>
              </>
            )}
          </div>
        )}
      </div>

      {showCompositionModal && (
        <BookingRoomsCompositionModal
          initialValue={[composition]}
          onRequestClose={() => {}}
          onSave={(value) => {
            setShowCompositionModal(false);
            addExtra(false, value?.[0]);
          }}
          translationKey="experiences"
          removeChildren={false}
          maxAdults={experienceComposition?.adults}
          maxChildren={experienceComposition?.children}
        />
      )}
    </>
  ) : null;
}
