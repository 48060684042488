import { AppContext } from '../contexts';
import { useEffect, useState } from 'react';
import { get } from '../api';
import {useAuth, useSnack} from '../hooks';
import {useTranslation} from 'react-i18next';

const permissions = {
  inviteUser: ['to_admin'],
  readSelfOrganization: ['to_admin', 'to_user' ,'other'],
  manageSelfOrganization: ['to_admin'],
  manageSelfOrganizationUsers: ['to_admin'],
};

const AppProvider = ({ children }) => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const { notify } = useSnack();

  const [user, setUser] = useState(null);

  useEffect(() => {
    get('/api/v1/user').then(r => setUser(r.data));
  }, []);

  const currentUserRole = user?.role;

  if (currentUserRole && (currentUserRole === 'dmc_admin' || currentUserRole === 'other')) {
    logout();
    notify(t('errors:userNotAuthorized'), { type: 'error' });
  }

  const canManageUser = targetUser => {
    if (currentUserRole === 'to_admin') return true;

    if (targetUser?.id === user?.id) {
      return false;
    }

    return false;
  };

  const isAllowedTo = name =>
    (permissions[name] || []).indexOf(currentUserRole) !== -1;

  return (
    <AppContext.Provider
      value={{
        user: user,
        currentUserRole,
        currentOrganizationId: user?.organizations?.[0]?.id,
        currentOrganization: user?.organizations?.[0],
        currentOrganizationIdInt:
          user?.organizations?.[0]?.organizationId,
        currentUserLang: user?.organizations?.[0]?.language,
        canManageUser,
        isAllowedTo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
