import { Stack, SegmentedControl } from '@koob/margaret';
import { MdList, MdMap } from 'react-icons/md';
import { IoMapOutline, IoPeopleOutline, IoFlag, IoCompassOutline, IoPricetagOutline } from 'react-icons/io5';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, {useCallback, useState} from "react";
import { useApp, useTripDesigner } from '../../hooks';
import { useAsync } from 'react-async';
import { Button, SearchableSelect, Tooltip } from 'components';
import BookingRoomsCompositionModal from '../Hotel/BookingRoomsCompositionModal';
import { getOrganizationByTo, getOrganizationByToDmc } from 'api/requestConnection';
import DatePicker from 'components/Fields/DatePicker';
import { getFolders } from 'api/folders';
import FoldersModal from 'containers/Folders/Partials/FoldersModal';
import { DurationButton, DurationModal } from '@koob/experiences'
import KoobModal from '../Koob/KoobModal';
import LocalMultiSearchableSelectField from '../Fields/LocalMultiSearchableSelectField';
import { getCountriesByDmcId } from '../../api/countries';
import { Form, Formik } from 'formik';
import KoobButton from '../Koob/KoobButton';
import KoobGroupedButtons from '../Koob/KoobGroupedButtons';
import { getThemes } from 'api/themes';
import { getSegments } from 'api/segments';
import Select from 'react-select';
import ScratchFiltersModal from './ScratchFiltersModal';
import moment from 'moment';
import { getKinds } from 'api/kinds';
import { getStyles } from 'api/styles';
import { getSustainableLevels } from 'api/sustainableLevels';
import { StarsPicker } from 'components/Fields';
import ComposeVersionSelector from '../../containers/TripDesigner/Compose/Partials/ComposeVersionSelector';
import { TiMessages } from "react-icons/ti";
import { IcCancel } from '../icons';
import { Input } from 'ui';
import { ExperienceFiltersPriceRange } from '@koob/experiences';
import { SEARCH_TYPES, SearchInput } from "@koob/search";

const StarsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 20px;
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

const statuses = [
  {
    label: "NEW",
    displayName: 'new'
  },
  {
    label: "PENDING DMC",
    displayName: 'pending_dmc'
  },
  {
    label: "PENDING TO",
    displayName: 'pending_to'
  },
  {
    label: "CLOSED",
    displayName: 'closed'
  }
];

const TripsFilter = ({
  tripId,
  versions,
  currentVersion,
  reloadTrip
}) => {
  const HEIGHT = 42;
  const { t } = useTranslation('tripDesigner');
  const {
    tripFilters,
    setTripFilters,
    tripData,
    searchHotels,
    setSearchHotels,
    itineraryPinLevel,
    setItineraryPinLevel,
    listMode,
    setListMode
  } = useTripDesigner();
  const theme = useTheme();

  const {currentOrganization} = useApp();
  const [compositionsModalIsShown, setCompositionsModalIsShown] = useState(false);
  const [foldersModalIsShown, setFoldersModalIsShown] = useState(false);
  const [durationModalShown, setDurationModalShown] = useState(false);
  const [showCountriesModal, setShowCountriesModal] = useState(false);
  const [scratchFiltersModalIsShown, setScratchFiltersModalIsShown] = useState(false);
  const [wrapped, setWrapped] = useState(true);
  const dmcId = tripFilters?.dmcId?.value ?? tripFilters?.dmcId;
  const [priceRangeModalIsShown, setPriceRangeModalIsShown] = useState(false);

  const toggleScratchFiltersModal = () => {
    setScratchFiltersModalIsShown(prevState => !prevState);
  };

  const { data } = useAsync({ promiseFn: getOrganizationByTo });
  const datas = (data?.data || []).map(dmc => ({
    value: dmc.id,
    label: dmc?.displayName,
  }));

  const { data: countriesData } = useAsync({
    promiseFn: getCountriesByDmcId,
    dmcId,
    watch: dmcId,
  });
  const countries = countriesData?.data || [];

  const getEndDate = () => {
    const date = new Date(tripFilters.departureDate);
    date.setDate(date.getDate() + tripFilters?.tripDuration);

    return tripFilters.departureDate == null
      ? null
      : date.toISOString().split('T')[0];
  };

  const { data: toConnectionRequestExperience } = useAsync({
    promiseFn: currentOrganization?.organizationId ? getOrganizationByToDmc : null,
    toOrganizationId: parseInt(currentOrganization?.organizationId),
    dmcOrganizationId: dmcId,
    kind: 'experience',
    watch: currentOrganization?.organizationId
  });
  const tripCurrency = toConnectionRequestExperience?.data?.[0]?.currency

  const updateFilterValue = useCallback(
    (property, value) => {
      setTripFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setTripFilters],
  );

  const { data: themesData } = useAsync({ promiseFn: getThemes });
  const { data: segmentsData } = useAsync({ promiseFn: getSegments });

  const { data: kindsData } = useAsync({
    promiseFn: getKinds,
  });
  const { data: stylesData } = useAsync({
    promiseFn: getStyles,
  });
  const { data: sustainableLevelsData } = useAsync({
    promiseFn: getSustainableLevels,
  });

  return (
    <div
      className={[
        'z-10 p-5 w-full bg-white rounded-xl shadow-k',
        (['map', 'itinerary'].includes(listMode)) ? 'fixed top-28 max-w-[96vw]' : 'mt-5',
      ].join(' ')}
    >
      <ScratchFiltersModal
        open={scratchFiltersModalIsShown}
        close={toggleScratchFiltersModal}
        update={true}
      />

      <div className="relative flex flex-row gap-1.5 justify-between">
        {!['chat', 'itinerary'].includes(listMode) && (
          <div className='relative flex gap-1.5 max-w-screen-2xl flex-wrap'>
            <div>
              <SearchInput
                value={tripFilters?.location?.[0]}
                onChange={value => updateFilterValue('location', value ? [value] : [])}
                types={[
                  SEARCH_TYPES.CITY,
                  SEARCH_TYPES.REGION,
                  SEARCH_TYPES.COUNTRY,
                  searchHotels ? SEARCH_TYPES.HOTEL : SEARCH_TYPES.PROGRAM,
                ]}
                fromOrganizationId={tripFilters?.dmcId?.value}
              />
            </div>

            <div>
              <DatePicker
                value={tripFilters.departureDate}
                minDate={new Date()}
                onChange={value => updateFilterValue('departureDate', value ?? null)}
                position="fixed"
              />
            </div>

            <div className="bg-gray-200 text-gray-600 font-medium px-4 rounded-full">
              <div className="h-full flex justify-center items-center space-x-1.5">
                &rarr; {moment(getEndDate()).format('DD / MM / YYYY')}
              </div>
            </div>

            <div>
              <Button
                onClick={() => {
                  setShowCountriesModal(true);
                }}
                variant="simple"
                style={{ width: 'auto', height: HEIGHT, borderRadius: '20px' }}
              >
                <Stack gutterSize={1}>
                  <Stack gutterSize={0.375} alignY="center">
                    <MdMap size="18" color="grey" />

                    <span>
                      {t('designTripModal.form.countries')} (
                        {tripFilters?.countries?.length})
                    </span>
                  </Stack>
                </Stack>
              </Button>
            </div>

            {!searchHotels && !wrapped && (
              <>
                <div>
                  <Select
                    placeholder={t('search.experienceTheme')}
                    name="themes"
                    options={themesData?.data}
                    getOptionValue={value => value?.id}
                    getOptionLabel={value => value?.displayName}
                    value={tripFilters.themes}
                    onChange={value => updateFilterValue('themes', value)}
                    isMulti
                    isSearchable={false}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '20px',
                        height: HEIGHT,
                        width: 'auto',
                        padding: '0 0.5rem',
                        border: `1px solid ${theme.separator}`,
                      }),
                    }}
                  />
                </div>

                <div>
                  <Select
                    placeholder={t('search.experienceSegment')}
                    name="segments"
                    options={segmentsData?.data}
                    getOptionValue={value => value?.id}
                    getOptionLabel={value => value?.displayName}
                    value={tripFilters.segments}
                    onChange={value => updateFilterValue('segments', value)}
                    isMulti
                    isSearchable={false}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '20px',
                        height: HEIGHT,
                        padding: '0 0.5rem',
                        border: `1px solid ${theme.separator}`,
                      }),
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <Input
                    placeholder={t('search.startLocation')}
                    defaultValue={tripFilters.startingPoint}
                    onKeyUp={event =>
                      updateFilterValue('startingPoint', event.target.value)
                    }
                    style={{
                      width: 'auto',
                      height: HEIGHT,
                      borderRadius: '20px',
                    }}
                  />
                  <Input
                    placeholder={t('search.endLocation')}
                    defaultValue={tripFilters.endingPoint}
                    onKeyUp={event =>
                      updateFilterValue('endingPoint', event.target.value)
                    }
                    style={{
                      width: 'auto',
                      height: HEIGHT,
                      borderRadius: '20px',
                    }}
                  />
                </div>
              </>
            )}

            {searchHotels && !wrapped && (
              <>
                <div>
                  <StarsContainer>
                    <StarsPicker
                      value={tripFilters?.stars}
                      onChange={value => updateFilterValue('stars', value)}
                      shouldShowInactiveStars
                      canceleable={true}
                    />
                  </StarsContainer>
                </div>

                <div>
                  <Select
                    placeholder={t('hotel:hotelTypology')}
                    name="kinds"
                    options={kindsData?.data}
                    getOptionValue={value => value?.id}
                    getOptionLabel={value => value?.displayName}
                    value={tripFilters.kinds}
                    onChange={value => updateFilterValue('kinds', value)}
                    isMulti
                    isSearchable={false}
                    defaultValue={tripFilters.kinds}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '20px',
                        height: HEIGHT,
                        padding: '0 0.5rem',
                        border: `1px solid ${theme.separator}`,
                      })
                    }}
                  />
                </div>

                <div>
                  <Select
                    placeholder={t('hotel:hotelStyle')}
                    name="styles"
                    options={stylesData?.data}
                    getOptionValue={value => value?.id}
                    getOptionLabel={value => value?.displayName}
                    value={tripFilters.styles}
                    onChange={value => updateFilterValue('styles', value)}
                    isMulti
                    isSearchable={false}
                    defaultValue={tripFilters.styles}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '20px',
                        height: HEIGHT,
                        padding: '0 0.5rem',
                        border: `1px solid ${theme.separator}`,
                      }),
                    }}
                  />
                </div>

                <div>
                  <Select
                    placeholder={t('hotel:sustainableLevel')}
                    name="sustainableLevel"
                    options={sustainableLevelsData?.data}
                    getOptionValue={value => value?.id}
                    getOptionLabel={value => value?.displayName}
                    value={tripFilters.sustainableLevel}
                    onChange={value =>
                      updateFilterValue('sustainableLevel', value)
                    }
                    isSearchable={false}
                    defaultValue={tripFilters.sustainableLevel}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: '20px',
                        height: HEIGHT,
                        padding: '0 0.5rem',
                        border: `1px solid ${theme.separator}`,
                      }),
                    }}
                    isClearable
                  />
                </div>
              </>
            )}

            {!searchHotels && !wrapped && (
              <div>
                <DurationButton
                  translation={t}
                  minDuration={tripFilters?.expDuration?.min ?? 0}
                  maxDuration={tripFilters?.expDuration?.max}
                  setDurationModalShown={setDurationModalShown}
                  onCancel={() => updateFilterValue('expDuration', null)}
                />
              </div>
            )}

            <div>
              <Button
                variant="simple"
                onClick={() => setCompositionsModalIsShown(true)}
                style={{ width: 150, height: HEIGHT, borderRadius: '20px' }}
              >
                <Stack gutterSize={1}>
                  <Stack gutterSize={0.375} alignY="center">
                    <IoPeopleOutline size={18} />

                    <span>
                      {t('search.nGuests', {
                        count: tripFilters?.composition?.count
                      })}
                    </span>
                  </Stack>
                </Stack>
              </Button>
            </div>

            {!searchHotels && !wrapped &&
              (
                <Stack>
                  <Button
                    variant="simple"
                    onClick={() => setPriceRangeModalIsShown(true)}
                    style={{ width: 150, height: HEIGHT, borderRadius: '20px' }}
                  >
                    <Stack gutterSize={1}>
                      <Stack gutterSize={0.375} alignY="center">
                        <IoPricetagOutline size={18} />

                        <span>
                          {t('search.priceRange')}
                        </span>
                      </Stack>
                    </Stack>
                  </Button>
                </Stack>
              )
            }

            <div>
              <SearchableSelect
                placeholder={t('search.dmcName')}
                renderSelectedOption={value => value?.label}
                datas={datas}
                value={tripFilters.dmcId}
                onChange={value => updateFilterValue('dmcId', value)}
                setValue={() => {}}
                locale
                disabled={!!tripFilters?.dmcId}
                inputStyle={{ minWidth: 100, height: HEIGHT }}
                wrapperStyle={{
                  marginLeft: 0,
                  minWidth: 100,
                  padding: 0,
                  height: HEIGHT,
                  '--location-search-trigger-height': `${HEIGHT}px`,
                }}
              />
            </div>

            {!searchHotels && !wrapped && (
              <div>
                <Button
                  variant={tripFilters?.guided ? 'primary' : 'simple'}
                  style={{ width: 150, height: HEIGHT, borderRadius: '20px' }}
                  onClick={() =>
                    updateFilterValue('guided', !tripFilters?.guided ?? true)
                  }
                >
                  <Stack gutterSize={1}>
                    <Stack gutterSize={0.375} alignY="center">
                      <IoFlag size={18} />
                      <span>
                        {tripFilters?.guided
                          ? t('search.guided')
                          : t('search.notGuided')}
                      </span>
                    </Stack>
                  </Stack>
                </Button>
              </div>
            )}

            {!wrapped && (
              <div>
                <SearchableSelect
                  placeholder={t('search.customerFile')}
                  query={getFolders}
                  renderSelectedOption={value => value?.icr + ' ' + value?.name}
                  onChange={value => updateFilterValue('clientFolder', value)}
                  wrapperStyle={{
                    marginLeft: 0,
                    minWidth: 100,
                    padding: 0,
                    height: HEIGHT,
                    '--location-search-trigger-height': `${HEIGHT}px`,
                  }}
                  inputStyle={{ minWidth: 100, height: HEIGHT }}
                  value={tripFilters?.clientFolder}
                  setValue={value => updateFilterValue('clientFolder', value)}
                  addElement={() => {
                    setFoldersModalIsShown(true);
                  }}
                  disabled={tripData?.clientFolder?.id !== undefined}
                />
              </div>
            )}

            <div className="bg-gray-200 text-gray-600 font-medium px-4 rounded-full">
              <div className="h-full flex justify-center items-center space-x-1.5">
                <IoPeopleOutline size={18} />

                <div>
                  {t('search.pax', {
                    minPax: tripFilters?.minPax || 0,
                    maxPax: tripFilters?.maxPax || 0,
                  })}
                </div>
              </div>
            </div>

            {tripFilters?.clientFolder?.id && (
              <ComposeVersionSelector
                tripId={tripId}
                currentVersion={currentVersion}
                versions={versions}
                reloadTrip={reloadTrip}
              />
            )}

            <KoobButton
              onClick={toggleScratchFiltersModal}
              color="primary"
            >
              {t('update')}
            </KoobButton>
          </div>
        )}

        {listMode === 'chat' && (
          <div className='relative flex gap-1.5 max-w-screen-2xl flex-wrap'>
            <div>
              <SearchableSelect
                placeholder={t('search.conversationStatus')}
                renderSelectedOption={value => value?.label}
                datas={statuses}
                value={tripFilters.conversationStatus}
                onChange={value => updateFilterValue('conversationStatus', value)}
                setValue={() => {}}
                locale
                inputStyle={{ minWidth: 100, height: HEIGHT }}
                wrapperStyle={{
                  marginLeft: 0,
                  minWidth: 100,
                  padding: 0,
                  height: HEIGHT,
                  '--location-search-trigger-height': `${HEIGHT}px`,
                }}
              />
            </div>
          </div>
        )}

        {listMode === 'itinerary' && (
          <>
            <SegmentedControl
              value={searchHotels}
              onSelect={value => setSearchHotels(value)}
              options={[
                {
                  value: false,
                  label: (
                    <div className="text-sm">
                      <i className="fa fa-xmark mr-1" />
                      Hide hotels
                    </div>
                  )
                },
                {
                  value: true,
                  label: (
                    <div className="text-sm">
                      <i className="fa fa-hotel mr-1" />
                      Show hotels
                    </div>
                  )
                },
              ]}
            />

            <SegmentedControl
              value={itineraryPinLevel}
              onSelect={value => setItineraryPinLevel(value)}
              options={[
                {
                  value: 0,
                  label: (
                    <div className="text-sm text-center leading-tight">
                      <i className="fa fa-map-pin mr-1.5" />
                      1
                      <br/><span className="text-xs">{t('itinerary.pinLevel1')}</span>
                    </div>
                  )
                },
                {
                  value: 1,
                  label: (
                    <div className="text-sm text-center leading-tight">
                      <i className="fa fa-map-pin mr-1.5" />
                      2
                      <br/><span className="text-xs">{t('itinerary.pinLevel2')}</span>
                    </div>
                  )
                },
                {
                  value: 2,
                  label: (
                    <div className="text-sm text-center leading-tight">
                      <i className="fa fa-map-pin mr-1.5" />
                      3
                      <br/><span className="text-xs">{t('itinerary.pinLevel3')}</span>
                    </div>
                  )
                },
              ]}
            />
          </>
        )}

        <div className="flex justify-between">
          <div className="flex gap-3">
            {!['chat', 'itinerary'].includes(listMode) && (
              <>
                <KoobGroupedButtons
                  value={wrapped}
                  onSelect={value => setWrapped(value)}
                  options={[
                    {
                      value: true,
                      label: (
                        <Tooltip
                          tip={t('tooltip.lessFilters')}
                          position="top"
                          hasArrow={true}>
                          <div className="py-0.5 flex space-x-0.5 items-center">
                            <svg className="h-4" viewBox="0 0 512 512">
                              <path
                                d="M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z"/>
                            </svg>
                            <svg className="h-3" viewBox="0 0 448 512">
                              <path
                                d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z"/>
                            </svg>
                          </div>
                        </Tooltip>
                      )
                    },
                    {
                      value: false,
                      label: (
                        <Tooltip
                          tip={t('tooltip.moreFilters')}
                          position="top"
                          hasArrow={true}>
                          <div className="py-0.5 flex space-x-0.5 items-center">
                            <svg className="h-4" viewBox="0 0 512 512">
                              <path
                                d="M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z"/>
                            </svg>
                            <svg className="h-3" viewBox="0 0 448 512">
                              <path
                                d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"/>
                            </svg>
                          </div>
                        </Tooltip>
                      )
                    },
                  ]}
                />

                <KoobGroupedButtons
                  value={searchHotels}
                  onSelect={value => setSearchHotels(value)}
                  options={[
                    {
                      value: false,
                      label: (
                        <Tooltip
                          tip={t('tooltip.experiences')}
                          position="top"
                          hasArrow={true}>
                          <div className="py-0.5">
                            <svg className="h-4" viewBox="0 0 576 512">
                              <path
                                d="M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"/>
                            </svg>
                          </div>
                        </Tooltip>
                      ),
                    },
                    {
                      value: true,
                      label: (
                        <Tooltip
                          tip={t('tooltip.hotels')}
                          position="top"
                          hasArrow={true}>
                          <div className="py-0.5">
                            <svg className="h-4 w-4" viewBox="0 0 512 512">
                              <path
                                d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48h8V464H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V48h8c13.3 0 24-10.7 24-24s-10.7-24-24-24H24zM432 48V464H304V384h32c8.8 0 16.1-7.2 14.7-15.9C343.1 322.6 303.6 288 256 288s-87.1 34.6-94.7 80.1c-1.5 8.7 5.8 15.9 14.7 15.9h32v80H80V48H432zM144 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H144zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zM336 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H336zM128 208v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zm112-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/>
                            </svg>
                          </div>
                        </Tooltip>
                      ),
                    },
                  ]}
                />
              </>
            )}

            <KoobGroupedButtons
              id="listMode-selector"
              value={listMode}
              onSelect={value => setListMode(value)}
              options={[
                {value: 'list', label:
                    <Tooltip
                    tip={t('tooltip.listView')}
                    position="top"
                    hasArrow={true}>
                      <MdList size={20}/>
                    </Tooltip>
                },
                {value: 'map', label:
                    <Tooltip
                      tip={t('tooltip.mapView')}
                      position="top"
                      hasArrow={true}>
                      <IoMapOutline size={20}/>
                    </Tooltip>
                },
                {value: 'itinerary', label:
                    <Tooltip
                      tip={t('tooltip.itineraryMap')}
                      position="top"
                      hasArrow={true}>
                      <IoCompassOutline size={20}/>
                    </Tooltip>
                },
                {value: 'chat', label:
                    <Tooltip
                      tip={t('tooltip.conversations')}
                      position="top"
                      hasArrow={true}>
                      <TiMessages size={20}/>
                    </Tooltip>
                },
              ]}
            />
          </div>
        </div>
      </div>

      {compositionsModalIsShown && (
        <BookingRoomsCompositionModal
          initialValue={[tripFilters?.composition]}
          onRequestClose={() => setCompositionsModalIsShown(false)}
          onSave={composition => {
            updateFilterValue('composition', composition[0]);
            setCompositionsModalIsShown(false);
          }}
          translationKey="experiences"
          maxAdults={tripFilters?.maxPax}
          hasNationalityField
          canHaveChildCompositions
        />
      )}

      {foldersModalIsShown && (
        <FoldersModal
          open={foldersModalIsShown}
          setOpen={setFoldersModalIsShown}
          onFolderCreated={customerFile => {
            updateFilterValue('clientFolder', customerFile);
          }}
        />
      )}

      {durationModalShown && (
        <DurationModal
          open={durationModalShown}
          setOpen={setDurationModalShown}
          initialValue={{
            min: tripFilters?.expDuration?.min ?? 0,
            max: tripFilters?.expDuration?.max ?? tripFilters?.expDuration ?? 1,
          }}
          max={50}
          onSave={value => {
            updateFilterValue('expDuration', value)
            setDurationModalShown(false)
          }}
          translation={t}
        />
      )}

      {showCountriesModal && (
        <KoobModal
          open={showCountriesModal}
          setOpen={setShowCountriesModal}
          overflow="visible"
        >
          <div className="px-5">
            <Formik
              initialValues={{
                countries: tripFilters.countries,
              }}
              onSubmit={values => {
                updateFilterValue('countries', values.countries);
                setShowCountriesModal(false);
              }}
            >
              <Form>
                <div className="my-20">
                  <LocalMultiSearchableSelectField
                    label={t('designTripModal.form.countries')}
                    name={`countries`}
                    options={countries?.map(country => ({
                      value: country.id,
                      label: country.title,
                    }))}
                    renderOption={option => option.label}
                    renderSelectedOption={option => option.label}
                  />
                </div>

                <KoobButton type="submit" block>
                  {t('misc:continue')}
                </KoobButton>
              </Form>
            </Formik>
          </div>
        </KoobModal>
      )}

      {priceRangeModalIsShown &&
        (
          <ExperienceFiltersPriceRange
            open={priceRangeModalIsShown}
            setOpen={setPriceRangeModalIsShown}
            priceRange={tripFilters?.priceRange ?? [0, null]}
            onSave={priceRange => {
              updateFilterValue('priceRange', priceRange);
              setPriceRangeModalIsShown(false);
            }}
            translation={t}
            currency={tripCurrency}
          />
        )
      }
    </div>
  );
};

export default TripsFilter;
