import { SEARCH_TYPES } from "./enums";

export const SEARCH_DEBOUNCE = 500;
export const SEARCH_MIN_LENGTH = 2;

export const SEARCH_DEFAULT_TYPES = [
  SEARCH_TYPES.CITY,
  SEARCH_TYPES.REGION,
  SEARCH_TYPES.COUNTRY,
];
