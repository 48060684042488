import React, { useState } from 'react';
import ExperienceType from '../../constants/ExperienceType';
import {useExperienceBooking} from "../../hooks";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {formatCurrency, formatDate} from "../../utils";
import {Button, Stack} from "@koob/margaret";
import KoobTitle from "../Koob/KoobTitle";
import KoobButton from "../Koob/KoobButton";
import { useNavigate } from "react-router-dom";
import {BookingState} from "../Hotel/Booking/BookingRecap";
import { useAsync } from 'react-async';
import { getExperience } from '../../api/experiences';
import ExperienceExtraComposition from '../../containers/Experiences/Partials/ExperienceExtraComposition';
import ExperienceExtrasModal from '../../containers/Experiences/Partials/ExperienceExtrasModal';
import { useBooking, useTripDesigner } from '../../hooks/useContexts';
import KoobDropdownListDescription from 'components/Koob/KoobDropdownListDescription';
import BookingCountdown from "../Hotel/Booking/BookingCountdown";
import KoobModal from '../Koob/KoobModal';
import KoobBadge from 'components/Koob/KoobBadge';

const LigneHorizontal = styled.hr`
  border: 0.00001em solid #d1d5db;
  width: 99%;
  opacity: 0.4;
  margin-top: 5px;
`;

const Promotion = styled.div`
  background-color: rgba(115, 201, 146, 0.12);
      color: ${({ theme }) => theme.primary} !important;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.295)} ${({ theme }) => theme.spacing(0.6)};
  text-align: center;
`;

const ModalCondition = styled.span`
  font-weight: 800;
  text-transform: lowercase;
  color: #1B8CE9;
  text-decoration: underline;
  text-decoration-color: #1B8CE9;
  cursor: pointer;
`

function ExperienceBasketProgramContentDay({ index, program, selectedHotel }) {
  const { t } = useTranslation('experiences');

  return (
    <>
      <div className="font-semibold leading-none">
        {`${t('basket.day')} ${(index + 1)} - ${program?.title}`}
      </div>

      {program?.accomodationType !== 'noAccomodation' ? (
        <>
          <div className="flex items-center space-x-1.5">
            {['hotel', 'freeAccomodation'].includes(program?.accomodationType) && (
              <svg className="h-4 w-4" viewBox="0 0 640 512">
                <path d="M32 48c0-8.8-7.2-16-16-16S0 39.2 0 48V336v64 64c0 8.8 7.2 16 16 16s16-7.2 16-16V416H608v48c0 8.8 7.2 16 16 16s16-7.2 16-16V400 336 240c0-61.9-50.1-112-112-112H304c-26.5 0-48 21.5-48 48V320H32V48zM608 384H32V352H272 608v32zm0-144v80H288V176c0-8.8 7.2-16 16-16H528c44.2 0 80 35.8 80 80zM96 208a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm128 0A80 80 0 1 0 64 208a80 80 0 1 0 160 0z"/>
              </svg>
            )}

            {program?.accomodationType === 'nightTransfer' && (
              <svg className="h-4 w-4" viewBox="0 0 384 512">
                <path d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 20.2 9.1 48.6 26.5 82.7c16.9 33.2 39.9 68.2 63.4 100.5c23.4 32.2 46.9 61 64.5 81.9c1.9 2.3 3.8 4.5 5.6 6.6c1.8-2.1 3.6-4.3 5.6-6.6c17.7-20.8 41.1-49.7 64.5-81.9c23.5-32.3 46.4-67.3 63.4-100.5C342.9 240.6 352 212.2 352 192zm32 0c0 88.8-120.7 237.9-170.7 295.9C200.2 503.1 192 512 192 512s-8.2-8.9-21.3-24.1C120.7 429.9 0 280.8 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"/>
              </svg>
            )}

            <div>
              {selectedHotel?.displayName ?? program?.accomodationHotel?.displayName ?? program?.accomodationExperience?.name}
              {selectedHotel?.bookedOnMyOwn && (
                <div className="font-medium text-orange-500">
                  {t('accomodationHotel.bookedOnMyOwn')}
                  <i className="fa fa-arrow-up-right-from-square ml-1.5" />
                </div>
              )}
            </div>

            <div className="text-sm font-medium">
              {program?.isIncludedAccomodation && (
                <div className="text-green-500">
                  {t('accomodationHotel.included')}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center space-x-1.5">
          <svg className="h-4 w-4" viewBox="0 0 384 512">
            <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"/>
          </svg>

          <div className="text-sm font-medium">
            {t('detail.programContent.noAccomodation')}
          </div>
        </div>
      )}
    </>
  )
}

function ExperienceBasketProgramContent({ experienceId, selectedHotels }) {
  const {data, isLoading} = useAsync({promiseFn: getExperience, experienceId});
  const programs = data?.data?.programs;

  return !isLoading ? (
    <div className="bg-gray-100 max-h-56 overflow-y-scroll rounded-lg p-4">
      <div className="text-gray-500 flex flex-col space-y-3">
        {programs.map((program, index) => (
          <ExperienceBasketProgramContentDay
            key={program?.id}
            index= {index}
            program={program}
            selectedHotel={selectedHotels?.find(h => h.programId === program?.id)}
          />
        ))}
      </div>
    </div>
  ) : null;
}

export const ExperienceBasketCard = ({experience, enableRemoval = true, isCard = true, showPrice= true, setNewRoom, isHotel, expirationDate, setExpirationDate, refreshBasket, bookingDone}) => {
  const { t} = useTranslation('experiences',{ keyPrefix: 'basket'});
  const { removeExperienceFromBasket, setBasket, basket } = useExperienceBooking();
  const navigate = useNavigate();
  const {
    setFilters,
    filters
  } = useBooking();
  const { tripFilters } = useTripDesigner();

  const handleChooseRoom = () => {
    setFilters({
      filters,
      startAt:
        isHotel && !Boolean(tripFilters?.tripId)
          ? experience.filters.startAt
          : formatDate(experience.filters.startAt, 'yyyy-MM-dd'),
      endAt:
        isHotel && !Boolean(tripFilters?.tripId)
          ? experience.filters.endAt
          : formatDate(experience.filters.endAt, 'yyyy-MM-dd'),
      internalId: experience.internalId,
      rooms: experience.filters.experienceComposition,
      templateId: experience.filters?.templateId,
    });
    navigate(`/hotels/${experience.hotelId}/booking`);
  };

  const handleDeleteRoom = () => {
    const id = basket.indexOf(basket.find(elem => elem.id === experience.id))
    setBasket(basket => {
      const newBasket = [...basket]
      newBasket[id].selectedRooms = []
      return newBasket
    })
    setExpirationDate(undefined)
    refreshBasket()
  }

  const participants =
    (experience.filters.experienceComposition?.adults ??
      experience.filters.experienceComposition?.reduce(
        (acc, curr) => acc + curr?.adults,
        0,
      )) +
    (experience.filters.experienceComposition?.childrenBirthdates?.length ??
      experience.filters.experienceComposition?.reduce(
        (acc, curr) => acc + curr?.childrenBirthdates?.length,
        0,
      ));

  let experiencePriceWithPromo = experience?.price?.price;
  let experiencePriceWithoutPromo = experience?.price?.totalPriceWithoutPromo;
  for (const extra of experience?.selectedExtras ?? []) {
    const pax = extra.perPax ? extra.adultCount ?? 1 + extra.childrenBirthdates?.length ?? 0 : 1;
    experiencePriceWithPromo += extra.price * pax;
    experiencePriceWithoutPromo += extra.priceWithoutPromo * pax;
  }
  if (experiencePriceWithoutPromo < 0) {
    experiencePriceWithoutPromo = 0;
    experiencePriceWithPromo = 0;
  }

  const experienceInfos = (
    <>
      {isHotel && experience.selectedRooms && expirationDate && !bookingDone && (
        <div className="absolute block right-0 top-0">
          {expirationDate && (
            <BookingCountdown
              expiryDate={expirationDate}
              resetTunnel={() => handleDeleteRoom()}
            />
          )}
        </div>
      )}

      <div
        className={[
          'mt-1 text-gray-800 font-medium w-full',
          isCard ? 'text-xl text-center' : 'text-2xl text-center'
        ].join(' ')}
      >
        {experience.name}
      </div>

      <div
        className={[
          'text-gray-500 w-full',
          isCard ? 'text-base text-center' : 'text-xl text-center'
        ].join(' ')}
      >
        {[experience?.inCity?.title ?? experience?.city?.title, experience?.inCity?.country?.title ?? experience?.country?.title].filter(Boolean).join(', ')}
      </div>

      <div className="mt-3 text-xl text-center text-gray-800 w-full">
        <div className="flex justify-center items-center space-x-3">
          <div>
            {formatDate(experience.filters.startAt, 'dd/MM/yyyy')}
          </div>

          <i className="far fa-arrow-right text-gray-400" />

          <div>
            {formatDate(experience.filters.endAt, 'dd/MM/yyyy')}
          </div>
        </div>
      </div>

      {(!isCard && ExperienceType.isProgram(experience?.experienceType)) && (
        <ExperienceBasketProgramContent
          experienceId={experience?.id}
          selectedHotels={experience?.selectedHotels}
        />
      )}

      <div className="mt-3 text-gray-500 flex justify-between items-center">
        <div className="flex space-x-1.5 items-center">
          <i className="far fa-users"/>

          <div>
            {t('participants', {
              count: participants
            })}
          </div>
        </div>

        <div className="text-right">
          {showPrice && experience?.price?.price && (
            <div>
              {experience?.price.price !== experience?.price?.totalPriceWithoutPromo && (
                <div className="text-sm font-medium line-through">
                  {formatCurrency({
                    amount: experiencePriceWithoutPromo,
                    currency: experience?.price.currency,
                  })}
                </div>
              )}

              {experience?.price?.currency && (
                <>
                  <div className="text-lg text-orange-600 font-semibold">
                    {formatCurrency({
                      amount: experiencePriceWithPromo,
                      currency: experience?.price?.currency,
                    })}
                  </div>

                  <div className="text-sm text-gray-600 font-medium">
                    {formatCurrency({
                      amount: experiencePriceWithPromo / participants,
                      currency: experience?.price?.currency,
                    })} / {t('pax')}
                  </div>
                </>
              )}
            </div>
          )}

          {!experience.price && (
            <div className="text-lg font-semibold">
              {t('notAvailable')}
            </div>
          )}
        </div>
      </div>

      {isCard && experience?.type !== 'hotel' && (
        <div className="my-2">
          <LigneHorizontal/>

          <KoobDropdownListDescription
            description={{
              description: {displayName: t('optionsLabel')},
            }}
            customContent={<ExperienceBasketExtras
              experience={experience}
              selectedExtras={experience?.selectedExtras}
              currency={experience?.price?.currency}
              bookingDone={false}
            />}
          />
        </div>
      )}

      {experience.price?.promotions &&
        <Stack style={{flexWrap: 'wrap', marginTop: '5px', marginBottom: '5px'}}>
          {(experience.price?.promotions || []).map((promo, index) => (
            <Promotion
              key={'promo_' + index}
              style={{marginRight: '5px'}}
            >
              {promo}
            </Promotion>
          ))}
        </Stack>
      }

      {experience.bookingId && (
        <div className="mt-3">
          <KoobButton
            onClick={() => {
              setNewRoom(true);
              navigate(`/bookings/${experience.bookingId}`)
            }}
          >
            {t('goToBooking')}
          </KoobButton>
        </div>
      )}
    </>
  )

  return (
    <div className={`relative flex-none mb-3 mx-1 p-4 ${isCard ? 'border rounded-lg overflow-hidden' : ''}`}>
      {enableRemoval && (
        <div className="absolute top-5 right-5">
        <button
            onClick={() => {removeExperienceFromBasket(experience)}}
            className="text-gray-500/75 hover:text-gray-500 outline-none border-0"
          >
            <svg className="h-5 w-5" viewBox="0 0 512 512">
              <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/>
            </svg>
          </button>
        </div>
      )}

      {experience.state && (
        <div className="mb-5">
          <BookingState state={experience?.state} type={t('experience')}/>
        </div>
      )}

      {!isCard && experience?.type === 'hotel' && (
        <>
          {experienceInfos}
          {(!experience.selectedRooms || experience?.selectedRooms?.length <= 0) && (
            <div className='flex justify-center items-center'>
              <div className='w-80'>
                <Button
                variant="primary"
                tabIndex="2"
                size={'full'}
                onClick={handleChooseRoom}
                >
                  {t('chooseRoom')}
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      {(experience?.type !== "hotel" || isCard) && (
        <>{experienceInfos}</>
      )}
    </div>
  )
};

export default function ExperiencesBasket({closeMenu}) {
  const {basket} = useExperienceBooking();
  const {t} = useTranslation('experiences', {keyPrefix: 'basket'});
  const navigate = useNavigate();

  return (
    <div className="fixed right-0 top-0 bg-white">
      <div className="h-screen w-[700px] shadow-r-k">
        <div className="pt-4 px-4 h-full flex flex-col pb-4">
          <KoobTitle className="mt-3 mb-6">
            {t('title')}
          </KoobTitle>

          {basket.length === 0 && (
            <div>
              {t('empty')}
            </div>
          )}

          <div className="flex flex-1 flex-col overflow-y-auto">
            {basket.map((experience) => (
              <ExperienceBasketCard
                experience={experience}
                key={experience.internalId}
              />
            ))}
          </div>

          {basket.length !== 0 && (
            <KoobButton onClick={() => {navigate('/experiences/checkout'); closeMenu()}}>
              {t('checkout')}
            </KoobButton>
          )}
        </div>
      </div>
    </div>
  )
}

export const SecondaryOptions = ({ selectedExtras, priceObj, currency }) => {
  const { t } = useTranslation('experiences');

  return (
    <>
      <div className="bg-gray-100 rounded-lg p-4 max-w-1/2">
        <div className="flex flex-col space-y-3">
          {selectedExtras.length > 0 ? selectedExtras.map((extra, key)=> {
            let extraTitle = null;
            const pax = extra.perPax ? extra.adultCount ?? 1 + extra.childrenBirthdates?.length ?? 0 : 1;
            const price = extra.price * pax;
            const priceWithoutPromo = extra.priceWithoutPromo * pax;
            return (
              <>
                {extraTitle}

                <div key={key} className={'flex flex-col space-y-1'}>
                  <div className="flex space-x-3 items-center">
                    <div className="font-medium text-gray-500 text-sm">
                      {extra.name}
                    </div>

                    <ExperienceExtraComposition
                      adults={extra.adultCount ?? 0}
                      childs={extra.childrenBirthdates?.length ?? 0}
                    />

                    <div className="font-medium text-sm">
                      <div className="flex space-x-2 justify-end text-orange-600">
                        <div>
                          {formatCurrency({
                            amount: price,
                            currency: extra?.currency ?? currency,
                          })}
                        </div>

                        {extra?.price !== extra?.priceWithoutPromo && (
                          <div className="text-xs line-through text-gray-500">
                            {formatCurrency({
                              amount: priceWithoutPromo,
                              currency: priceObj?.currency ?? currency,
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='flex-end'>
                      <KoobBadge color={extra?.type === 'required' ? 'orange' : ''}>
                        {extra?.type}
                      </KoobBadge>
                    </div>
                  </div>
                </div>
              </>
            )
          }) : null}
        </div>
      </div>
    </>
  )
}

export const ExperienceBasketExtras = ({
  selectedExtras,
  setSelectedExtras,
  experience,
  priceObj,
  currency,
  bookingDone
}) => {
  const { t } = useTranslation('experiences');
  const { experienceFilters } = useExperienceBooking();

  const [showContractingCondition, setShowContractingCondition] = useState(false);
  const [extraId, setExtraId] = useState();
  const [showExtrasModal, setShowExtrasModal] = useState(false);

  const allowChanges = !!setSelectedExtras;

  selectedExtras.sort((a, b) => {
    if (!a?.dayIndex) return 1;
    if (!b?.dayIndex) return -1;

    return a.dayIndex - b.dayIndex;
  });
  let currentOptionGroup = null;

  const { data } = useAsync({
    promiseFn: getExperience,
    experienceId: experience?.id
  });

  const includedExtras = selectedExtras
    ?.filter(extra => !extra.price)
    .map(extra => extra.id);

  const experienceExtras = (data?.data?.extras ?? [])
    .filter(extra => !includedExtras.includes(extra.id));

  return (
    <>
      <div className="bg-gray-100 rounded-lg p-4 max-w-1/2">
        <div className="flex flex-col space-y-3">
          {selectedExtras.length > 0 ? (
            selectedExtras
              ?.filter(se => !se.parentExtraId)
              ?.map((extra, key) => {
                let extraTitle = null;

                if (currentOptionGroup !== extra?.dayIndex) {
                  extraTitle = (
                    <div className="text-sm uppercase font-semibold text-gray-500">
                      {`${t('extras.title')} - ${
                        extra?.dayIndex
                          ? `${t(
                            'detail.programContent.day',
                          )} ${extra.dayIndex}`
                          : 'global'
                      }`}
                    </div>
                  );
                  currentOptionGroup = extra?.dayIndex;
                }
                const extraChildren = selectedExtras.filter(se => se.parentExtraId === extra.id);
                const pax = extra.perPax ? extra.adultCount ?? 1 + extra.childrenBirthdates?.length ?? 0 : 1;
                let totalExtraPrice =  extra?.price !== 0 && extra.price ? extra.price * pax : 0;
                let totalExtraPriceWithoutPromo = extra?.priceWithoutPromo * pax ?? 0;
                extraChildren.forEach(extraChild => {
                  totalExtraPrice += extraChild?.price * (extraChild.perPax ? extraChild.adultCount + extraChild.childrenBirthdates?.length : 1) ?? 0;
                  totalExtraPriceWithoutPromo += extraChild?.priceWithoutPromo * (extraChild.perPax ? extraChild.adultCount + extraChild.childrenBirthdates?.length : 1) ?? 0;
                });

                return (
                  <>
                    {extraTitle}

                    <div
                      key={key}
                      className={
                        allowChanges ?
                          'flex justify-between items-center'
                          : 'flex flex-col space-y-1'
                      }
                    >
                      <div className="flex space-x-3 items-center">
                        <div className="font-medium text-gray-500 text-sm">
                          {extra.name}
                        </div>

                        <ExperienceExtraComposition
                          adults={extra.adultCount ?? 0}
                          childs={extra.childrenBirthdates?.length ?? 0}
                        />
                      </div>


                      <div className="font-medium text-sm">
                        {(
                          extra?.price !== 0 && extra.price
                        ) ? (
                          <div className="flex space-x-2 justify-end text-orange-600">
                            <div>
                              {formatCurrency({
                                amount: totalExtraPrice,
                                currency: extra?.currency ?? currency,
                              })}
                            </div>

                            {extra?.price !== extra?.priceWithoutPromo && (
                              <div className="text-xs line-through text-gray-500">
                                {formatCurrency({
                                  amount: totalExtraPriceWithoutPromo,
                                  currency: priceObj?.currency ?? currency,
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>{`${t('extras.included')}`}</div>
                        )}
                      </div>
                    </div>

                    {selectedExtras?.filter(se => se.parentExtraId === extra.id)?.length > 0 && (
                      <KoobDropdownListDescription
                        description={{
                          description: { displayName: t('optionsLabel') },
                        }}
                        customContent={
                          <SecondaryOptions
                            selectedExtras={selectedExtras?.filter(
                              se => se.parentExtraId === extra.id,
                            )}
                            priceObj={priceObj}
                            bookingDone={bookingDone}
                          />
                        }
                      />
                    )}

                    {(
                      (
                        extra?.price !== 0 && extra.price
                      ) &&
                      <div className='flex space-x-2 justify-end'>

                        <div className="flex space-x-2 justify-end">
                          <div>
                            {formatCurrency({
                              amount: totalExtraPrice / (
                                pax
                              ),
                              currency: extra?.currency ?? currency,
                            })} / PAX
                          </div>

                          {extra?.price !== extra?.priceWithoutPromo && (
                            <div className="text-xs line-through text-gray-500">
                              {formatCurrency({
                                amount: totalExtraPriceWithoutPromo / (
                                  pax
                                ),
                                currency: priceObj?.currency ?? currency,
                              })} / PAX
                            </div>
                          )}
                        </div>
                      </div>
                    )}


                    {extra.cancellationPolicies && (
                      <div className="font-medium text-gray-500 text-sm">
                        {t('basket.agreementTextOne')}
                        <ModalCondition
                          onClick={() => {
                            setExtraId(key)
                            setShowContractingCondition(true)
                          }}
                        >
                          {t('basket.conditions')}
                        </ModalCondition>

                        {t('basket.agreementTextTwoOption')}
                      </div>
                    )}
                  </>
                );
              })
          ) : (
            <div className="font-medium text-gray-500 text-sm">
              {t('extras.noExtras')}
            </div>
          )}
        </div>
      </div>

      {showContractingCondition &&
        <KoobModal
          open={true}
          setOpen={() => setShowContractingCondition(false)}
          widthClass="sm:max-w-5xl"
        >
          <div className="max-h-[600px] overflow-y-auto">
            <div className="px-3">
              <KoobTitle>
                {t('basket.contractingPolicies')}
              </KoobTitle>

            </div>
            <div className="mt-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedExtras[extraId].cancellationPolicies
                }}
              />
            </div>
          </div>

          <div className="mt-5 flex justify-end">
            <KoobButton onClick={() => {
              setShowContractingCondition(false);
            }}>
              {t('misc:close')}
            </KoobButton>
          </div>
        </KoobModal>
      }

      {allowChanges && (
        <ExperienceExtrasModal
          open={showExtrasModal}
          setOpen={setShowExtrasModal}
          experience={data?.data}
          experienceComposition={experienceFilters?.experienceComposition?.[0]}
          extras={experienceExtras}
          prices={priceObj?.extras}
          currency={priceObj?.currency}
          selectedExtras={selectedExtras.filter(extra => !includedExtras?.includes(extra.id))}
          setSelectedExtras={setSelectedExtras}
          onContinue={() => setShowExtrasModal(false)}
        />
      )}
    </>
  )
}
