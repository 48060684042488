import { useState } from "react";
import SearchFilter from "./SearchFilter";
import SearchResult from "./SearchResult";

export default function SearchResults({ query, results, onSelect }) {
  const [selectedType, setSelectedType] = useState(null);

  const allTypes = [...new Set(results.map((result) => result.type))];

  const filteredResults = selectedType
    ? results.filter((result) => result.type === selectedType)
    : results;

  return (
    <div>
      {allTypes?.length > 1 && (
        <SearchFilter
          types={allTypes}
          results={results}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      )}

      <div className="max-h-80 scroll-py-2 overflow-y-auto">
        {filteredResults.map((result) => (
          <SearchResult
            key={`search-result-${result.type}-${result.id}`}
            result={result}
            query={query}
            onClick={() => onSelect(result)}
          />
        ))}
      </div>
    </div>
  );
}
