import SearchCategory from "./SearchCategory";
import { SEARCH_TYPE_ICONS } from "../enums";

export default function SearchFilter({ types, results, selectedType, setSelectedType }) {
  const typesWithCount = types
    ?.map((type) => ({
      type,
      count: results.filter((result) => result.type === type).length,
    }))
    ?.sort((a, b) => b.count - a.count);

  return (
    <div className="pb-3 flex space-x-3 items-center">
      {selectedType && (
        <SearchCategory
          label="All"
          icon="fa-delete-left"
          onSelect={() => setSelectedType(null)}
        />
      )}

      {typesWithCount.map((item) => (
        <SearchCategory
          key={item.type}
          label={`${item.type} • ${item.count}`}
          isSelected={selectedType === item.type}
          onSelect={() => setSelectedType(item.type)}
        />
      ))}
    </div>
  );
}
