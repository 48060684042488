import { Transition } from '@headlessui/react';
import { Fragment } from "react";

export default function SearchPanel({ isOpen, onClose, children }) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className="relative z-50" onClick={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/10 transition-opacity" />
        </Transition.Child>

        <div className="absolute inset-x-0 top-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="mt-2 mx-auto max-w-2xl transform divide-y divide-gray-500/10 overflow-hidden rounded-xl bg-white/90 shadow-2xl ring-1 ring-black/5 backdrop-blur backdrop-filter transition-all">
              <div className="p-4">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
}
