import { useAsync } from 'react-async';
import {getHotel, getHotels} from '../../api/hotels';
import {Link, useNavigate, useParams} from 'react-router-dom';
import BlocRight from 'components/Hotel/HotelDetails/BlocRight';
import { useTranslation } from 'react-i18next';
import ServiceAndFacilities from 'components/Hotel/HotelDetails/ServiceAndFacilities';
import SustainableCommitments from 'components/Hotel/HotelDetails/SustainableCommitments';
import RestaurantsHotel from 'components/Hotel/HotelDetails/RestaurantsHotel';
import styled from 'styled-components';
import KoobContainer from 'components/Koob/KoobContainer';
import {Spinner, Stack} from '@koob/margaret';
import React, { useCallback, useEffect, useState } from 'react';
import {useBooking, useError, useLanguage, useSnack} from '../../hooks';
import { isEmpty, omit } from 'lodash';
import MeetingRooms from 'components/Hotel/HotelDetails/MeetingRooms';
import KoobAccordeonBlocComponent from 'components/Koob/KoobAccordeonBlocComponent';
import KoobDescriptionComponent from 'components/Koob/KoobDescriptionComponent';
import KoobLocationComponent from 'components/Koob/KoobLocationComponent';
import KoobHeader from 'components/Koob/KoobHeader';
import {SectionCard, StarsPicker} from 'components/Fields';
import {formatCurrency} from "../../utils";
import {RoleButton as Button} from "../../components/RoleButton";
import {add, addDays, differenceInDays, format, parseISO} from "date-fns";
import {requestConnection} from "../../api/requestConnection";
import { BlocImages } from 'components/Hotel/HotelDetails/BlocImages';
import KoobPlaceholder from '../../components/Koob/KoobPlaceholder';
import BookingRoomsCompositionModal from 'components/Hotel/BookingRoomsCompositionModal';
import { Tooltip } from 'components';
import { useExperienceBooking } from "../../hooks";
import { ApiPollingStateEnum } from "@koob/enums";

export const SpinnerContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${({theme}) => theme.spacing(2)};
  margin-bottom: ${({theme}) => theme.spacing(2)};
`;

export default function HotelsDetail() {
  const { hotelId } = useParams();
  const { t } = useTranslation('hotel');
  const { filters, setFilters, setCustomerFile,replaceBookingId } = useBooking();
  const { lang } = useLanguage();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();
  const navigate = useNavigate();
  const { data, reload } = useAsync({
    promiseFn: getHotel,
    hotelId,
    locale: lang
  });

  const [isLoading, setIsLoading] = useState(true);
  const [showRoomCompositionModal, setShowRoomCompositionModal] = useState(undefined);
  const [basketAvailable, setBasketAvailable] = useState(true);

  const updateFilterValue = useCallback(
    (property, value) => {
      setFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setFilters],
  );

  const requestFilters= {
    hotels: [hotelId],
    rooms: filters?.rooms?.map(room => ({
      ...omit(room, 'children'),
      firstAdultNationality: room?.firstAdultNationality?.value,
      count: 1,
    })),
    startAt: filters?.startAt,
    endAt: filters?.endAt
  };

  const totalPax = filters?.rooms?.reduce((acc, room) => acc + room.adults + room.childrenBirthdates?.length ?? 0, 0);

  const {
    data: priceData,
    reload: reloadPrice
  } = useAsync({
    promiseFn: getHotels, filters: requestFilters,
    onResolve: (results) => {
      if (results?.data?.state === ApiPollingStateEnum.IN_PROGRESS) {
        setTimeout(() => {
          reloadPrice();
        }, 3000);
      }
    },
    onReject: () => {
      setTimeout(() => {
        reloadPrice();
      }, 5000);
    }
  });

  const hotelPriceObj = (priceData?.data?.data?.length > 0)
    ? priceData.data.data[0]
    : undefined;
  const hotel = data?.data;
  const minimumStay = hotelPriceObj?.minimumStay;
  let canReload = filters?.experienceId && minimumStay === undefined && !isEmpty(hotelPriceObj);
  const canDisplay = filters?.experienceId  && !canReload && isEmpty(hotelPriceObj) && priceData?.data?.state ==='finished';
  const { addExperienceToBasket } = useExperienceBooking();

  useEffect(() => {
    const programBookingFilters = JSON.parse(localStorage.getItem('programBookingInfos'));
    const isFromProgram =
      !isEmpty(programBookingFilters?.experienceComposition) &&
      !isEmpty(programBookingFilters?.startAt) &&
      !isEmpty(programBookingFilters?.endAt);
    const startDate = programBookingFilters?.startAt ? parseISO(programBookingFilters?.startAt) : null;
    const programDate = startDate ? format(addDays(startDate,programBookingFilters?.day),'yyyy-MM-dd') : null;

    if (isFromProgram) {
      setFilters(current => ({
        ...current,
        rooms: programBookingFilters?.experienceComposition,
        experienceId: programBookingFilters?.experienceId,
        programDay: programBookingFilters?.day,
        startAt: programDate,
        endAt: format(add(new Date(programDate), { days: 1 }), 'yyyy-MM-dd'),
        minDate: programBookingFilters?.startAt,
        maxDate: programBookingFilters?.endAt,
      }));
      if (programBookingFilters?.clientFolder) {
        setCustomerFile(programBookingFilters.clientFolder);
      }
      localStorage.removeItem('programBookingInfos');
      setShowRoomCompositionModal(true);
    }
  }, []);

  useEffect(() => {
    if (hotel) {
      setIsLoading(false);
    }
  })

  const cancelationPoliciesObj = (hotel?.cancelationPolicies && hotel?.cancelationPolicies.length>0) ? {
    content: hotel?.cancelationPolicies,
    description: {
      displayName: t('contractingPolicies')
    }
  } : undefined;

  const mainDescription = hotel?.hotelDescriptions?.find(
    (elem) => elem.content && elem.description.isMainDescription
  );

  let accordionRows = hotel?.hotelDescriptions?.filter(
    (elem) => elem.content && !elem.description.isMainDescription
  ) ?? [];

  if (cancelationPoliciesObj) {
    accordionRows = [
      cancelationPoliciesObj,
      ...accordionRows,
    ]
  }

  const hotelImages = [];

  hotelImages.push(hotel?.primaryAttachment);
  if (hotel?.secondaryAttachments?.length > 0) {
    hotelImages.push(...hotel.secondaryAttachments);
  }

  (hotel?.restaurants ?? []).forEach(restau => {
    restau?.attachments.forEach(attach => {
      hotelImages.push(attach);
    });
  });

  (hotel?.meetingRooms ?? []).forEach(room => {
    room?.attachments.forEach(attach => {
      hotelImages.push(attach);
    });
  });

  const hasFilter =
    filters?.startAt &&
    filters?.endAt &&
    filters?.rooms &&
    filters?.rooms?.length > 0;

  const nbNights = differenceInDays(
    new Date(filters?.endAt),
    new Date(filters?.startAt),
  );

  const handleRequest = async () => {
    try {
      await requestConnection(hotel?.organization?.organizationId, 'hotel');
      reload();
      notify(t('requestConnectionSuccess'));
    } catch (error) {
      sendErrorSnack(error);
    }
  };

  const handleBookingStart = () => {
    navigate('booking/');
  };

  const handleAddToBasket = () => {
    addExperienceToBasket({
      ...hotel,
      durationDays: filters?.startDate && filters?.endDate ? differenceInDays(new Date(filters?.endDate), new Date(filters?.startDate)) : 0,
      type: "hotel",
      name: hotelPriceObj?.displayName,
      selectedExtras: [],
      imageUrl: hotelPriceObj?.imageUrl,
      hotelId: hotel?.id,
      price: {
        promotions: [],
      },
    },
    {startAt: filters?.startAt,
      endAt: filters?.endAt,
      experienceComposition: filters?.rooms,
      hotel: [{id: hotel?.id}]});
    notify(t('addedToBasket'));
  }

  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source');

  return (
    <>
      <KoobContainer>
        {isLoading && (
          <SpinnerContainer>
            <Spinner/>
          </SpinnerContainer>
        )}

        {hotel && (
          <div className="max-w-5xl mx-auto">
            {!replaceBookingId && (
              <div className="flex items-center space-x-1 mb-3">
                <Link to="/hotels" className="text-gray-500 font-medium hover:text-orange-500 transition flex items-center space-x-1">
                  <svg className="h-4 w-4" viewBox="0 0 448 512">
                    <path d="M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z"/>
                  </svg>
                  <div className="text-lg">
                    {t('backToCatalog')}
                  </div>
                </Link>

                {!isEmpty(hotelPriceObj) && !source && (
                  <Tooltip
                    tip={t('hotelBookingInfo')}
                    color="purple"
                    hasArrow={false}
                    position="right-bottom"
                    maxWidth={512}
                  >
                    <svg className="h-5 w-5 no-hover" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
                    </svg>
                  </Tooltip>
                )}
              </div>
            )}

            <Stack>
              <BlocImages
                images={hotelImages}
              />
            </Stack>

            <div className="grid md:grid-cols-3 gap-5">
              <div className="relative w-full col-span-2">

                <div className="w-full">
                  <KoobHeader title={hotel?.displayName} tags={hotel?.kinds.map((elem) => elem.displayName)}>
                    { hotel?.stars && <StarsPicker value={hotel?.stars}/> }
                  </KoobHeader>

                  <KoobDescriptionComponent
                    title={t('description')}
                    description={mainDescription?.content}
                  />

                  <KoobAccordeonBlocComponent
                    accordeonDetail={accordionRows}
                    tags={(hotel?.styles??[]).map((elem) => elem.displayName)}
                    title={t('hotelStyle')}
                    translation={'hotel'}
                  />

                  <KoobLocationComponent
                    markers={[
                      {
                        lat: ''+hotel?.lat,
                        lon: ''+hotel?.lon,
                      }
                    ]}
                    title={t('location')}
                    address={hotel.address}
                    tags={(hotel?.locations ?? []).map((elem) => elem.displayName)}
                  />

                  <ServiceAndFacilities hotel={hotel}/>

                  <SustainableCommitments hotel={hotel}/>

                  <RestaurantsHotel hotel={hotel}/>

                  <MeetingRooms hotel={hotel}/>
                </div>
              </div>

              <div className="md:order">
                <BlocRight data={hotel} hotelPrice={hotelPriceObj} minimumStay={minimumStay}>
                  {!hotel?.hasToRequest && (
                    <SectionCard>
                      <Button
                        variant="primary"
                        tabIndex="2"
                        size={'full'}
                        disabledRoles={['to_user']}
                        disabledRolesMessage={t('requestConnectionNoRight')}
                        onClick={handleRequest}
                      >
                        {t('requestConnection')}
                      </Button>
                    </SectionCard>
                  )}

                  {hotel?.canBeBooked && hasFilter && (
                    <SectionCard>
                      <div className="flex flex-col space-y-3">
                        {hotelPriceObj && hotelPriceObj.price || canDisplay ? (
                          <>
                            <div className="text-gray-800 text-lg font-medium text-center">
                              {hotel?.displayName}
                            </div>

                            {hotelPriceObj?.price ? (
                              <>
                                <div className="flex justify-center items-center space-x-3">
                                  <div className="text-gray-500 text-sm">
                                    {t('from')}
                                  </div>

                                  <div className="text-3xl text-orange-600 font-medium">
                                    {formatCurrency({
                                      amount: hotelPriceObj?.price,
                                      currency: hotelPriceObj?.currency,
                                    })}
                                  </div>

                                  <div className="text-center text-gray-500 text-sm">
                                    {t('perNight')}
                                  </div>
                                </div>

                                <div className="text-xl text-center font-medium text-gray-800">
                                  {formatCurrency({
                                    amount: hotelPriceObj?.price / totalPax ?? 1,
                                    currency: hotelPriceObj?.currency,
                                  })} / PAX
                                </div>
                              </>
                            ) : (
                              <div className="text-gray-500 text-sm">
                                {t('unavailable')}
                              </div>
                            )}

                            {hasFilter && nbNights < minimumStay ? (
                              <div className="flex flex-col space-y-1">
                                <div className="text-center text-red-500 font-medium bg-red-100 rounded py-2">
                                  {t('minimumStay', { count: minimumStay })}
                                </div>

                                <Button
                                  variant="primary"
                                  type="button"
                                  tabIndex="2"
                                  size={'full'}
                                  onClick={() => setShowRoomCompositionModal(true)}
                                >
                                  {t('booking:editBookingPeriod')}
                                </Button>
                              </div>
                            ) : (
                              <>
                                <Button
                                  variant="primary"
                                  tabIndex="2"
                                  size={'full'}
                                  onClick={handleBookingStart}
                                  disabled={!hotelPriceObj?.price}
                                  id="book-now"
                                >
                                  {t('booking:newBooking')}
                                </Button>

                                <Button
                                  variant="primary"
                                  tabIndex="2"
                                  size={'full'}
                                  onClick={handleAddToBasket}
                                  disabled={!basketAvailable || !hotelPriceObj?.price}
                                >
                                  {t('booking:Add to basket')}
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <KoobPlaceholder className="w-2/3 mx-auto h-6 rounded" />

                            <KoobPlaceholder className="w-full px-2 h-10 rounded" />

                            <KoobPlaceholder className="w-full h-10 rounded" />
                          </>
                        )}
                      </div>
                    </SectionCard>
                  )}
                </BlocRight>
              </div>
            </div>
          </div>
        )}

        {showRoomCompositionModal && (
          <BookingRoomsCompositionModal
            multiselect
            initialValue={filters?.rooms}
            onRequestClose={() => {
              setShowRoomCompositionModal(false);
              setBasketAvailable(false);
            }}
            onSave={rooms => {
              updateFilterValue('rooms', rooms);
              setShowRoomCompositionModal(false);
              setBasketAvailable(false);
              reloadPrice();
            }}
            hasNationalityField
            hasPeriodField
          />
        )}
      </KoobContainer>
    </>
  );
}
