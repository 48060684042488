import { get } from "@koob/to/src/api";
import { SEARCH_TYPES } from "./enums";

export default function search ({ query, types = Object.values(SEARCH_TYPES), fromOrganizationId, skip = false }) {
  if (!query?.length > 0 || skip) {
    return Promise.resolve({ data: [] });
  }

  let url = `/api/v1/search?query=${query}&types=${types.join(',')}`;

  if (fromOrganizationId) {
    url += `&fromOrganizationId=${fromOrganizationId}`;
  }

  return get(url);
}
