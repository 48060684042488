import { useState, useEffect } from 'react';
import SearchInputField from './SearchInputField';
import SearchPanel from "./SearchPanel";
import SearchPanelEmptyState from "./SearchPanelEmptyState";
import SearchResults from "./SearchResults";
import { SEARCH_MIN_LENGTH, SEARCH_DEBOUNCE, SEARCH_DEFAULT_TYPES } from "../constants";
import { useAsync } from 'react-async';
import { useDebounce } from 'use-debounce';
import search from "../api";
import { encodeId } from "@koob/to/src/utils";
import { isEqual } from 'lodash';

export default function SearchInput({ types = SEARCH_DEFAULT_TYPES, fromOrganizationId, value, encodeIds = false, onChange, rounded }) {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, SEARCH_DEBOUNCE);
  const [isFocused, setIsFocused] = useState(false);
  const [showPanel, setShowPanel] = useState(false);

  const formattedQuery = debouncedQuery?.trim();

  const isQueryValid = formattedQuery?.length >= SEARCH_MIN_LENGTH;

  const { data, isLoading } = useAsync({
    promiseFn: search,
    skip: !isQueryValid,
    query: formattedQuery,
    types,
    fromOrganizationId,
    watchFn: (prev, next) => {
      return prev.query !== next.query || !isEqual(prev.types, next.types)
    },
  });
  const results = data?.data;

  useEffect(() => {
    if (isFocused) {
      setShowPanel(true);
    }
  }, [isFocused]);

  const displayComponent = () => {
    if (isLoading) {
      return (
        <SearchPanelEmptyState
          icon="fa-spinner-third fa-spin"
          title="Loading..."
        />
      );
    }

    if (!isQueryValid) {
      return (
        <SearchPanelEmptyState
          icon="fa-search"
          title={`Type to search ${types?.join(', ')}...`}
        />
      );
    }

    if (results?.length === 0) {
      return (
        <SearchPanelEmptyState
          icon="fa-xmark"
          title="No results found"
        />
      );
    }

    return (
      <SearchResults
        query={formattedQuery}
        results={results}
        onSelect={(value) => {
          if (!value) {
            onChange('');
            setQuery('');
            return;
          }
          if (encodeIds) {
            return onChange({
              ...value,
              id: encodeId(value.type.charAt(0).toUpperCase() + value.type.slice(1), value.id),
              kind: value.type
            });
          }
          onChange({
            ...value,
            kind: value.type
          });
          setQuery(value?.title);
        }}
      />
    )
  };

  return (
    <div className="relative w-60">
      <div
        className={[
          'relative',
          showPanel && 'z-[60]'
        ].join(' ')}
      >
        <SearchInputField
          types={types}
          value={value?.title ?? query ?? ''}
          onChange={v => {
            setQuery(v);
            if (!v) {
              onChange(null);
            }
          }}
          rounded={rounded}
          clearable={!!value}
          setIsFocused={setIsFocused}
        />
      </div>

      <div className="w-screen max-w-xl">
        <SearchPanel
          isOpen={showPanel}
          onClose={() => {
            setShowPanel(false);
            setIsFocused(false);
          }}
        >
          {displayComponent()}
        </SearchPanel>
      </div>
    </div>
  )
}
