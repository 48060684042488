export default function SearchPanelEmptyState({ icon, title }) {
  return (
    <div className="px-6 py-8 text-center sm:px-14">
      <i
        className={[
          'fal fa-2x mx-auto text-gray-500',
          icon
        ].join(' ')}
      />

      <div className="mt-4 max-w-md mx-auto">
        <p className="text-sm text-gray-500">
          {title}
        </p>
      </div>
    </div>
  );
}
