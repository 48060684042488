import { SEARCH_TYPE_ICONS } from "../enums";

export default function SearchTypeIcon({ type, className }) {
  return (
    <div className="w-5 flex justify-center">
      <i
        className={[
          SEARCH_TYPE_ICONS[type],
          'fal text-lg',
          className
        ].join(' ')}
      />
    </div>
  );
}
