import { useTranslation } from 'react-i18next';
import { requestConnection } from 'api/requestConnection';
import ExperienceType from '../../constants/ExperienceType';
import { useApp, useError, useExperienceBooking, useSnack, useTripDesigner } from '../../hooks';
import { useState } from 'react';
import KoobModal from '../Koob/KoobModal';
import KoobButton from '../Koob/KoobButton';
import KoobTitle from '../Koob/KoobTitle';
import ExperienceAvailability from './ExperienceAvailability';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ExperienceTypeIcon from '../../containers/Experiences/Partials/ExperienceTypeIcon';
import { useField } from 'formik';
import { formatCurrency } from 'utils';

const ExperienceCardLink = ({ to, disabled, className, children }) => {
  return (
    <>
      {disabled ? (
        <div className={className}>
          {children}
        </div>
      ) : (
        <Link to={to} className={className}>
          {children}
        </Link>
      )}
    </>
  );
};

export const ExperienceCard = ({
  experience,
  refreshExperiences,
  small,
  mode = 'list',
  onSelect,
  hasExperienceSummary,
  compatibility,
  isForTrip
}) => {
  const {t} = useTranslation('experiences');
  const {sendErrorSnack} = useError();
  const {notify} = useSnack();
  const {currentUserRole} = useApp();
  const navigate = useNavigate();
  const {selectedType} = useTripDesigner();
  const {setDates: setExperienceDates} = useExperienceBooking();
  const [{value: experienceTrip}, ,] = mode === 'compose' ? useField(`days`) : [{}];

  const [showAvailability, setShowAvailability] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);

  const isSelectedExperienceInTrip = experienceTrip && experienceTrip?.some(item => item?.experience?.id === experience?.id)

  const handleRequest = async e => {
    try {
      e.preventDefault();
      if (currentUserRole === 'to_user') {
        notify(t('requestConnectionNoRight'), {type: 'error'});
        return;
      }
      await requestConnection(experience?.organizationId, 'experience');
      if (refreshExperiences) {
        refreshExperiences();
      }
      notify(t('requestConnectionSuccess'));
    } catch (error) {
      sendErrorSnack(error);
    }
  };

  const handleClick = e => {
    e.preventDefault();
    setShowAvailability(true)
  }

  return (
    <>
      <ExperienceCardLink
        className="relative bg-white rounded-xl border shadow-k overflow-hidden"
        to={`${experience?.id}${!experience?.requested ? '?source=showcased' : ''}`}
        disabled={canRedirect || mode !== 'list'}
      >
        <div className={small && 'flex'} onClick={() => setCanRedirect(false)}>
          <div className="relative">
            {(!experience?.requested && !['trip-export', 'itinerary'].includes(mode)) && (
              <div
                className="absolute z-10 cursor-pointer backdrop-blur-sm bg-orange-500 bg-opacity-70 rounded-t-xl w-full p-3"
                onClick={handleRequest}
              >
                <div className="flex justify-center items-center space-x-1 text-white">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                    />
                  </svg>
                  <div>{t('requestConnection')}</div>
                </div>
              </div>
            )}

            {experience?.tags?.length > 0 && (
              <div className="absolute bottom-0 backdrop-blur-sm bg-purple-600 bg-opacity-50 w-full p-2">
                <div className="text-center text-white text-xs">
                  {experience?.tags?.map((style, index) => (
                    <span key={experience?.id + index}>
                      {style} {index !== experience.tags.length - 1 && ', '}
                    </span>
                  ))}
                </div>
              </div>
            )}

            <img
              className={[
                'w-full object-cover bg-gray-100',
                small ? 'h-44' : 'h-56'
              ].join(' ')}
              src={experience?.imageUrl ?? experience?.pictures?.[0]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
              onError={ev => (ev.target.src = '/img/hotel-image-placeholder.png')}
              alt=""
            />
          </div>

          <div className={small ? 'flex items-center px-4' : 'p-4'}>
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between">
                {isForTrip ? (
                  <a
                    href={`/experiences/${experience?.id}?source=trip`}
                    target="_blank"
                    className={'underline hover:text-blue-700'}
                    rel="noreferrer"
                  >
                    <div className="font-medium">
                      {experience?.name}
                    </div>
                  </a>
                ) : (
                  <div className="font-medium">
                    {experience?.name}
                  </div>
                )}

                {mode !== 'trip-export' && (
                  <ExperienceTypeIcon
                    sizeClass="w-6 h-6"
                    type={experience?.experienceType}
                    config={experience?.transferType}
                  />
                )}
              </div>

              <div className="flex justify-between items-center">
                <div className="flex space-x-3 flex-wrap text-gray-500 text-sm">
                  <div className="flex gap-1.5 items-center">
                    <i className="fal fa-clock text-lg" />

                    <div>
                      {experience?.durationDays === 0 && experience?.durationHours === 0 && experience?.durationMinutes === 0
                        ? t('durationUnitOptions.days', {count: experience?.durationDays}) : null} {' '}
                      {experience?.durationDays > 0 ? t('durationUnitOptions.days', {count: experience?.durationDays}) : null} {' '}
                      {experience?.durationHours > 0 ? t('durationUnitOptions.hours', {count: experience?.durationHours}) : null} {' '}
                      {experience?.durationMinutes > 0 ? t('durationUnitOptions.minutes', {count: experience?.durationMinutes}) : null} {' '}
                    </div>
                  </div>

                  <div className="flex space-x-1.5 items-center">
                    <i className="fal fa-flag text-lg"/>
                    <div>
                      {experience?.startPosition?.name}
                    </div>
                  </div>

                  <div className="flex space-x-1.5 items-center">
                    <i className="fal fa-flag-checkered text-lg"/>
                    <div>
                      {experience?.endPosition?.name}
                    </div>
                  </div>
                </div>
              </div>

              {(experience?.price?.price > 0) && (
                <div className="flex justify-end">
                  <div className="text-orange-600">
                    {formatCurrency({
                      amount: experience?.price?.price,
                      currency: experience?.price?.currency,
                    })}
                  </div>
                </div>
              )}
              
              <div className="flex justify-end gap-1">
                {(ExperienceType.isProgram(experience?.experienceType) && experience?.summary && hasExperienceSummary) && (
                  <div
                    className="col-span-2 prose prose-sm"
                    dangerouslySetInnerHTML={{__html: experience?.summary}}
                  />
                )}
              </div>

              <div className="z-10 mt-3 grid grid-cols-2 gap-3 items-center">
                {mode === 'list' && (
                  <>
                    <KoobButton
                      onClick={(e) => handleClick(e)}
                      disabled={!experience?.requested}
                      block
                    >
                      <div className="text-sm px-1 whitespace-nowrap">
                        {t('seeAvailability')}
                      </div>
                    </KoobButton>
                  </>
                )}

                {mode === 'compose' && (
                  <div>
                    {compatibility !== 'incompatible' ? (
                      <KoobButton
                        onClick={() => {
                          if (experience?.requested) {
                            onSelect(experience);
                          } else {
                            notify(t('requestConnectionToAdd'), {type: 'error'});
                          }
                        }}
                        color={compatibility === 'bestMatch' ? 'green' : 'primary'}
                        disabled={isSelectedExperienceInTrip || selectedType !== 'experience'}
                        block
                      >
                        <div className="flex justify-center space-x-1.5 items-center">
                          {compatibility === 'bestMatch' && (
                            <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                              <path
                                d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zm82.3 195.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"
                              />
                            </svg>
                          )}
                          <div className="text-sm font-medium whitespace-nowrap">
                            {t(selectedType === 'experience' ? 'misc:select' : 'tripDesigner:compose.selectDay')}
                          </div>
                        </div>
                      </KoobButton>
                    ) : (
                      <div className="w-full text-red-600 h-9 bg-gray-200 cursor-not-allowed px-4 rounded flex space-x-1.5 justify-center items-center">
                        <svg className="h-5 w-5" height="1em" viewBox="0 0 512 512">
                          <path
                            d="M243.5 37.3c8-3.4 17-3.4 25 0l176.7 75c11.3 4.8 18.9 15.5 18.8 27.6c-.5 94-39.4 259.8-195.5 334.5c-7.9 3.8-17.2 3.8-25.1 0C87.3 399.6 48.5 233.8 48 139.8c-.1-12.1 7.5-22.8 18.8-27.6l176.7-75zM281 7.8c-16-6.8-34-6.8-50 0L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L281 7.8zM180.7 180.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z"
                          />
                        </svg>
                        <span className="font-medium text-sm">{t('incompatible')}</span>
                      </div>
                    )}
                  </div>
                )}

                <div
                  className={[
                    'flex items-center space-x-1.5 text-gray-500',
                    mode === 'itinerary' && 'col-span-2'
                  ].join(' ')}
                >
                  <div className="w-full text-xs text-right font-medium leading-tight">
                    {experience?.organizationName}
                  </div>

                  <img
                    className="w-8 h-8 object-cover bg-gray-100 rounded-md"
                    src={experience?.organizationImages ?? '/img/hotel-image-placeholder.png'}
                    onError={ev => (ev.target.src = '/img/hotel-image-placeholder.png')}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ExperienceCardLink>

      <KoobModal
        open={showAvailability}
        setOpen={setShowAvailability}
        widthClass="sm:max-w-xl"
      >
        <KoobTitle className="text-center">
          {t('availability.title')}
        </KoobTitle>

        <div className="p-6">
          <ExperienceAvailability
            experienceId={experience?.id}
            onSelect={(dates) => {
              setExperienceDates(dates);
              setShowAvailability(false);
              navigate(experience?.id);
            }}
          />
        </div>
      </KoobModal>
    </>
  );
};

export default ExperienceCard;
