import { ButtonReset, Stack } from '@koob/margaret';
import {useEffect, useState} from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { camelCase, isEmpty} from 'lodash';
import { Field, FieldArray, Form, Formik, useField } from 'formik';
import {
  Container,
  LeftContainer,
  RightContainer,
  SegmentedControlField,
  SplitContainer,
} from 'components/Hotel/Booking/components';
import { formatDate, phoneRegex } from 'utils';
import HotelBookedSummary from './HotelBookedSummary';
import { useApp, useBooking, useSnack } from 'hooks';
import { DateField, EditorField } from 'components/Fields';
import { MdChevronRight } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { fontStyles } from 'ui';
import LoadFromTraveler from './LoadFromTraveler';
import { submitBook } from 'api/hotels';
import PhoneNumberField from 'components/Fields/PhoneNumberField';
import SelectNationatilyField from 'components/Fields/SelectNationatilyField';
import CancellationPolicies from "./Edit/CancellationPolicies";
import CheckboxField from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import { GrCircleQuestion } from 'react-icons/gr';
import KoobBadge from '../../Koob/KoobBadge';

const OptionalInfoWrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  alignX: 'stretch',
})`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const Summary = styled.div`
  position: sticky;
  top: 110px;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const FlexBlock = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding-left: 15px;
  margin-top: 15px;
  ${props =>
    Boolean(props.column) &&
    css`
      flex-direction: column;
    `};
  ${props =>
    Boolean(props.center) &&
    css`
      align-items: center;
    `};
`;

const InputContainer = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const FieldsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
`;

const RoomsContainer = styled.div`
  margin-top: 30px;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const RoomWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: 0 0 5px 3px rgba(214, 214, 214, 0.2);
  padding: 1.5rem;
  margin-right: ${({ theme }) => theme.spacing()};
`;

const TYPE_MAPPINGS = {
  adult: 'Adult',
  child: 'Child',
};

const ChildBirthdate = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-size: 14px;
  padding-left: 15px;
`;

const TopDetail = styled.div`
  margin: 0;
  border-left: 3px solid #d6d6d6;
  padding-left: 15px;
`;

const Refer = styled.span`
  margin-left: 10px;
`;

const RoomTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: gray;
  ${props =>
    props.condition &&
    css`
      color: #000;
    `}
`;

const ToInputContainer = styled(InputContainer)`
  display: flex;
  flex-direction: column;
`;
export const ErrorMessageWrapper = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.danger};
`;

const BookingField = ({ name, children }) => {
  const [field, meta] = useField(name);
  const hasError = Boolean(meta.error);
  return (
    <>
      {children(field)}
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </>
  );
};

const FormField = styled(Field)`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  min-height: 36px;
  width: 100%;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  background-color: #ffffff;

  ${({ type }) =>
    type === 'password' &&
    css`
      padding-right: 44px;
    `}
  &:not([disabled]):focus,
  &:not([disabled]):active, &:not([disabled]):hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.darkSeparator};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.danger};
      }
    `}
`;

export const ToInfos = ({ isDisabled }) => {
  const { t } = useTranslation('booking', { keyPrefix: 'form' });

  return (
    <RoomWrapper>
      <div className="text-xl font-medium text-center">
        {t('toInfo')}
      </div>

      <FieldsContainer>
        <FlexBlock>
          <ToInputContainer>
            <label htmlFor="booking.details.to.nameTitle">
              {t('tonameTitle')}
            </label>
            <BookingField name="tourOperatorName">
              {field => (
                <FormField
                  name="tourOperatorName"
                  type="text"
                  field={field}
                  disabled={true}
                />
              )}
            </BookingField>
          </ToInputContainer>

          <ToInputContainer>
            <label htmlFor="booking.details.to.network">
              {t('networkName')}
            </label>
            <BookingField name="networkName">
              {field => (
                <FormField
                  name="networkName"
                  field={field}
                  type="text"
                  disabled={isDisabled}
                />
              )}
            </BookingField>
          </ToInputContainer>
        </FlexBlock>
      </FieldsContainer>
    </RoomWrapper>
  );
};
const Asterix = () => {
  return (
    <span className="text-lg text-red-600 font-bold">*</span>
  )
}
export const BookingOwner = ({ isDisabled, isRequired }) => {
  const { t } = useTranslation('booking', { keyPrefix: 'form' });

  return (
    <RoomWrapper>
      <div className="text-xl font-medium text-center">
        {t('bookingInfo')}
      </div>

      <FieldsContainer>
        <FlexBlock column>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ToInputContainer>
              <label htmlFor="booking.details.firstname">
                {t('customerFirstNameTitle')} <Asterix/>
              </label>
              <BookingField name="customerFirstName">
                {field => (
                  <FormField
                    name="customerFirstName"
                    field={field}
                    type="text"
                    disabled={isDisabled}
                  />
                )}
              </BookingField>
            </ToInputContainer>

            <ToInputContainer>
              <label htmlFor="booking.details.lastname">
                {t('customerLastNameTitle')} <Asterix/>
              </label>
              <BookingField name="customerLastName">
                {field => (
                  <FormField
                    name="customerLastName"
                    field={field}
                    type="text"
                    disabled={isDisabled}
                  />
                )}
              </BookingField>
            </ToInputContainer>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ToInputContainer>
              <label htmlFor="booking.details.phonenumber">
                {t('phoneNumber')}
                {isRequired ? (
                  <Asterix/>
                ) : null}
              </label>
              <BookingField name="customerPhoneNumber">
                {field => (
                  <PhoneNumberField
                    className="mt-1"
                    name="customerPhoneNumber"
                    field={field}
                    type="text"
                    disabled={isDisabled}
                  />
                )}
              </BookingField>
            </ToInputContainer>

            <ToInputContainer>
              <label htmlFor="booking.details.email">
                {t('email')} <Asterix/>
              </label>
              <BookingField name="customerEmail">
                {field => (
                  <FormField
                    name="customerEmail"
                    field={field}
                    type="email"
                    disabled={isDisabled}
                  />
                )}
              </BookingField>
            </ToInputContainer>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ToInputContainer>
              <label htmlFor="booking.details.company">
                {t('companyTitle')}
              </label>
              <BookingField name="customerCompany">
                {field => (
                  <FormField
                    name="customerCompany"
                    type="text"
                    disabled={true}
                    field={field}
                  />
                )}
              </BookingField>
            </ToInputContainer>

            <ToInputContainer>
              <label htmlFor="booking.details.toReference">
                {t('toReference')}
              </label>
              <BookingField name="toReference">
                {field => (
                  <FormField
                    name="toReference"
                    type="text"
                    field={field}
                  />
                )}
              </BookingField>
            </ToInputContainer>
          </div>
        </FlexBlock>
      </FieldsContainer>
    </RoomWrapper>
  );
};

export const OptionalInfoFields = ({
  name,
  disabled,
  canUpdateNationality,
}) => {
  const { t } = useTranslation('booking', { keyPrefix: 'form' });
  const [isExpanded, setIsExpanded] = useState();

  return (
    <OptionalInfoWrapper>
      <ButtonReset
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ width: '100%' }}
      >
        <Stack size="full" alignX="space-between" padding={0.5}>
          <span>{t('optionalInfoLabel')}</span>
          <span style={isExpanded ? { transform: 'rotate(90deg)' } : undefined}>
            <MdChevronRight size={20} />
          </span>
        </Stack>
      </ButtonReset>

      {isExpanded && (
        <Stack direction="column" padding={0.5} gutterSize={1} size="full">
          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <BookingField name={`${name}.nationality`}>
                {field => (
                  <SelectNationatilyField
                    name={`${name}.nationality`}
                    placeholder={t('nationality')}
                    disabled={
                      canUpdateNationality !== undefined
                        ? !canUpdateNationality
                        : disabled
                    }
                    field={field}
                  />
                )}
              </BookingField>
            </div>

            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <BookingField name={`${name}.passportNumber`}>
                {field => (
                  <FormField
                    name={`${name}.passportNumber`}
                    type="text"
                    field={field}
                    placeholder={t('passportNumber')}
                    disabled={disabled}
                  />
                )}
              </BookingField>
            </div>

            <div
              style={{
                display: 'flex',
                flex: '0 0 fit-content',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <label htmlFor={`${name}.birthdate`}>{t('expirationDate')}</label>
              <BookingField name={`${name}.expirationDate`}>
                {field => (
                  <DateField
                    name={`${name}.expirationDate`}
                    datePlaceholder={t('expirationDate')}
                    field={field}
                    rounded
                    actionOnfocus={function () {
                      return null;
                    }}
                    disabled={disabled}
                  />
                )}
              </BookingField>
            </div>
          </div>
        </Stack>
      )}
    </OptionalInfoWrapper>
  );
};

export const RoomPerson = ({
  type,
  index,
  birthdate,
  isRoomLeader,
  name,
  setFieldValue,
  disabled,
  customerFile,
  validateForm,
  canUpdateNationality,
  ...props
}) => {
  const { t } = useTranslation('booking', { keyPrefix: 'form' });
  const [{ value }] = useField({ name });

  return (
    <>
      <TopDetail>
        <div className="mt-5 flex justify-between items-center">
          <div className="flex space-x-1.5 items-center">
            <div className="text-xl font-semibold">
              {TYPE_MAPPINGS[type]} {index}
            </div>

            {isRoomLeader && (
              <div>
                <KoobBadge>
                  {t('bookingReferee')}
                </KoobBadge>
              </div>
            )}

            <ChildBirthdate>
              {birthdate &&
                `${t('birthdate', {
                  date: formatDate(birthdate, 'dd/MM/yyyy'),
                })}`}
            </ChildBirthdate>
          </div>

          {!disabled && (
            <LoadFromTraveler
              type={type}
              customerFile={customerFile}
              loadTraveler={traveler => {
                if (traveler) {
                  setFieldValue(`${name}.id`, traveler.id);
                  setFieldValue(`${name}.gender`, traveler.gender);
                  setFieldValue(`${name}.firstname`, traveler.firstName);
                  setFieldValue(`${name}.lastname`, traveler.lastName);
                  setFieldValue(`${name}.birthdate`, traveler.birthdate);
                  if (canUpdateNationality) {
                    setFieldValue(`${name}.nationality`, traveler.nationality);
                  }
                  setFieldValue(
                    `${name}.passportNumber`,
                    traveler.passportNumber,
                  );
                  setFieldValue(
                    `${name}.expirationDate`,
                    traveler.passportExpirationDate,
                  );
                  if (typeof validateForm === 'function') {
                    validateForm();
                  }
                }
              }}
            />
          )}
        </div>

        <FieldsContainer>
          <BookingField name={`${name}.gender`}>
            {field => (
              <SegmentedControlField
                name={`${name}.gender`}
                field={field}
                options={[
                  { label: t('male'), value: 'male' },
                  { label: t('female'), value: 'female' },
                  {
                    label: t('other'),
                    value: 'non_binary',
                  },
                ]}
                disabled={disabled}
              />
            )}
          </BookingField>
        </FieldsContainer>
      </TopDetail>

      <FieldsContainer>
        <FlexBlock center>
          <InputContainer>
            <BookingField name={`${name}.firstname`}>
              {field => (
                <FormField
                  name={`${name}.firstname`}
                  type="text"
                  field={field}
                  placeholder={t('customerFirstName')}
                  disabled={disabled}
                />
              )}
            </BookingField>
          </InputContainer>

          <InputContainer>
            <BookingField name={`${name}.lastname`}>
              {field => (
                <FormField
                  name={`${name}.lastname`}
                  type="text"
                  field={field}
                  placeholder={t('customerLastName')}
                  disabled={disabled}
                />
              )}
            </BookingField>
          </InputContainer>

          <InputContainer>
            <BookingField name={`${name}.birthdate`}>
              {field => (
                <DateField
                  name={`${name}.birthdate`}
                  full
                  variant="booking"
                  datePlaceholder={t('birthdate')}
                  clearDateDisabled
                  allowPast
                  isMonthAndYearSelectionnable
                  field={field}
                  disabled={disabled}
                  actionOnfocus={function () {
                    return null;
                  }}
                />
              )}
            </BookingField>
          </InputContainer>

          {['infant','child'].includes(type) && (
            <InputContainer className="flex space-x-1 items-center">
              <CheckboxField
                label={t('definitiveDate')}
                name={`${name}.ageIsExact`}
                disabled={disabled}
                inCardPay
                nowrapLabel
              />

              {!value?.ageIsExact && (
                <Tooltip
                  tip={t('birthdateTip')}
                  color="purple"
                  hasArrow={false}
                  position="top"
                >
                  <GrCircleQuestion size={18} />
                </Tooltip>
              )}
            </InputContainer>
          )}
        </FlexBlock>

        <OptionalInfoFields
          name={name}
          disabled={disabled}
          canUpdateNationality={canUpdateNationality}
          {...props}
        />
      </FieldsContainer>
    </>
  );
};

const BookingInfos = ({ hotel }) => {
  const {
    filters,
    selectedRooms,
    travelerRooms,
    customerFile,
    requestId,
    replaceBookingId,
    setReplaceBookingId,
    setGuests,
    setExpirationDate,
    nationalities
  } = useBooking();
  const { t } = useTranslation('booking');
  const navigate = useNavigate();
  const { startAt, endAt } = filters;
  const { user } = useApp();
  const { notify } = useSnack();
  const { hotelId } = useParams();
  const ROOMS_COMPOSITIONS_SCHEMA = Yup.array()
    .of(
      Yup.object().shape({
        travelers: Yup.array()
          .of(
            Yup.object().shape({
              gender: Yup.string()
                .oneOf(['non_binary', 'male', 'female'])
                .required(t('required', { ns: 'errors' })),
              firstname: Yup.string().required(t('required', { ns: 'errors' })),
              lastname: Yup.string().required(t('required', { ns: 'errors' })),
              birthdate: Yup.date().required(t('required', { ns: 'errors' })),
            }),
          )
          .min(1),
      }),
    )
    .min(1);

  useEffect(() => {
    setExpirationDate(selectedRooms[0].expiresAt)
  }, []);

  const supplementTypes = ['supplement', 'supplement_percent'];

  const createBooking = async values => {
    const roomsToBook = selectedRooms.reduce((acc, curr, index) => {
      const valueToAdd = {};
      if (!supplementTypes.includes(curr?.bed?.promotionInfo?.kind)) {
        valueToAdd.promotionId = curr?.bed?.promotionInfo?.promotionId;
      }
      valueToAdd.bedCompositionId = curr.bed.id;
      valueToAdd.optionalPromos = curr.optionalPromos,
      valueToAdd.travelers = values.roomsToBook[index].travelers.map(v => ({
        id: v.id,
        gender: v.gender,
        firstName: v.firstname,
        lastName: v.lastname,
        nationality: v?.nationality?.value || '',
        passportNumber: v.passportNumber || '',
        kind: v.type,
        birthdate: v.birthdate,
        ageIsExact: v.ageIsExact ?? null,
        ...(v.expirationdate && { expirationDate: v.expirationdate }),
      }));
      acc.push(valueToAdd);
      return acc;
    }, []);

    try {
      const data = await submitBook({
        id: hotelId,
        data: {
          ...values,
          replaceBookingId: replaceBookingId ?? undefined,
          roomsToBook,
          customerFileId: customerFile?.id,
          id: requestId,
        },
      });
      if (replaceBookingId) {
        setReplaceBookingId(undefined);
        setGuests([]);
      }
      navigate(`/bookings/${data.data}/confirmation`);
    } catch (e) {
      const error = camelCase(e?.response?.data?.message ?? 'error');
      notify(t(`errors:${error}`), { type: 'error' });
    }
  };

  return (
    <>
      {
        <Container reservation>
          <Formik
            initialValues={{
              startAt,
              endAt,
              customerLastName: user?.lastName,
              customerFirstName: user?.firstName,
              customerCity: '',
              customerZipCode: '',
              customerAddress: '',
              customerAdditionalAddress: '',
              customerCompany: user?.organizations[0]?.displayName,
              customerEmail: user?.email || '',
              customerPhoneNumber: user?.phoneNumber || '',
              note: '',
              policiesConsent: false,
              roomsToBook: (travelerRooms ?? []).map(travelerRoom => {
                return {
                  ...travelerRoom,
                  travelers: (travelerRoom.travelers ?? []).map(traveler => ({
                    ...traveler,
                    nationality: nationalities.find(
                      nationality => nationality.value === traveler.nationality,
                    ),
                  })),
                };
              }),
              tourOperatorName: user?.organizations[0]?.displayName,
            }}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={createBooking}
            validationSchema={Yup.object().shape({
              customerEmail: Yup.string().email(t('errors:email')).required(t('errors:required')),
              customerFirstName: Yup.string().required(t('form.customerFirstNameRequired')),
              customerLastName: Yup.string().required(t('form.customerLastNameRequired')),
              policiesConsent: Yup.bool().oneOf(
                [true],
                'Field must be checked',
              ),
              customerPhoneNumber: Yup.string().matches(phoneRegex, t('errors:invalidFormat')).when([], {
                context: {
                  selectedRooms: selectedRooms,
                },
                is: () => selectedRooms[0].supplier === 'Rakuten',
                then: Yup.string().required(t('errors:required')),
                otherwise: Yup.string(),
              }),

              roomsToBook: ROOMS_COMPOSITIONS_SCHEMA,
            })}
          >
            {({ isValid, isSubmitting, handleSubmit, setFieldValue, validateForm }) => {
              return (
                <Form>
                  <SplitContainer reverse>
                    <LeftContainer reservation>
                      <Stack direction="column" gutterSize={1.8}>
                        <ToInfos />
                        <BookingOwner isRequired={selectedRooms[0].supplier === 'Rakuten'} />
                      </Stack>

                      <FieldArray name="booking">
                        {() => (
                          <RoomsContainer>
                            {travelerRooms.map((roomItem, roomIndex) => {
                              return (
                                <RoomWrapper key={roomIndex}>
                                  <RoomTitle>
                                    {t('room.roomSelectionIndex', {
                                      roomIndex: roomIndex + 1,
                                    })}
                                  </RoomTitle>

                                  <div>
                                    {roomItem.travelers
                                      .filter(
                                        traveler => traveler.type === 'adult',
                                      )
                                      .map((tr, travelerIndex) => (
                                        <RoomPerson
                                          type={tr.type}
                                          key={`adult-${tr.id}`}
                                          name={`roomsToBook.${roomIndex}.travelers.${travelerIndex}`}
                                          index={travelerIndex + 1}
                                          isRoomLeader={
                                            roomIndex === 0 &&
                                            travelerIndex === 0
                                          }
                                          customerFile={customerFile}
                                          setFieldValue={setFieldValue}
                                          canUpdateNationality={
                                            isEmpty(
                                              roomItem.travelers[0].nationality,
                                            ) || travelerIndex !== 0
                                          }
                                          validateForm={() => validateForm()}
                                        />
                                      ))}

                                    {roomItem.travelers
                                      .filter(
                                        traveler => traveler.type === 'child',
                                      )
                                      .map((tr, travelerIndex) => {
                                        return (
                                          <RoomPerson
                                            type={tr.type}
                                            key={`child-${tr.id}`}
                                            name={`roomsToBook.${roomIndex}.travelers.${
                                              travelerIndex +
                                              roomItem.travelers.filter(
                                                traveler =>
                                                  traveler.type === 'adult',
                                              ).length
                                            }`}
                                            customerFile={customerFile}
                                            index={travelerIndex + 1}
                                            setFieldValue={setFieldValue}
                                            validateForm={() => validateForm()}
                                          />
                                        );
                                      })}
                                  </div>
                                </RoomWrapper>
                              );
                            })}
                          </RoomsContainer>
                        )}
                      </FieldArray>

                      <CancellationPolicies
                        rooms={selectedRooms}
                        bedField="bed"
                      />
                    </LeftContainer>

                    <RightContainer reservation>
                      <Summary>
                        <HotelBookedSummary
                          startDate={filters.startAt}
                          endDate={filters.endAt}
                          hotel={hotel}
                          disabledButton={!isValid || isSubmitting}
                          rooms={selectedRooms}
                          isInfo={true}
                          roomsFilter={filters?.rooms}
                          step={2}
                          handleNextStep={handleSubmit}
                        >
                          <Stack style={{ marginBottom: '2rem' }}>
                            <FieldsContainer style={{ flex: 1 }}>
                              <RoomTitle>{t('form.notes')}</RoomTitle>
                              <EditorField
                                variant="simple"
                                className="h-64"
                                name={'note'}
                                fillHeight
                                customHeight={160}
                                enchanced
                              />
                            </FieldsContainer>
                          </Stack>
                        </HotelBookedSummary>
                      </Summary>
                    </RightContainer>
                  </SplitContainer>
                </Form>
              );
            }}
          </Formik>
        </Container>
      }
    </>
  );
};

export default BookingInfos;
