import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Card as RawCard, Container, Text } from 'ui';
import { useTranslation } from 'react-i18next';
import { ButtonReset, Stack } from '@koob/margaret';
import { useFormikContext } from 'formik';
import { FormField, FormLabel } from 'ui/forms';
import { get, groupBy, keys, sortBy, values } from 'lodash';
import {IcAddCircle, IcAddCircleMulti, IcCheckMark, IcMail} from 'components/icons';
import Search from 'components/Search';
import { useDebounce } from 'react-use';
import { getCountriesWithQuery } from '../../api/countries';
import { getRegionsWithQuery } from '../../api/regions';
import { useAsync } from 'react-async';
import { useEffect } from 'react';
import Spinner from 'components/Spinner';
import {useApp} from "../../hooks";
import OrganizationCountryBookingReceptionEmailsModal from "../OrganizationCountryBookingReceptionEmailsModal";

const RegionLine = styled(ButtonReset)`
  ${({ isChecked, theme }) =>
    isChecked &&
    `
      background-color: ${theme.background};
    `}
`;

const CountryLine = styled(Stack).attrs({
  paddingVertical: 0.75,
  paddingLeft: 0.75,
  paddingRight: 1.25,
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
})`
  ${({ isChecked, theme }) =>
    isChecked &&
    `
      background-color: ${theme.background};
    `}
`;

const Card = styled(RawCard)`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 0;
`;

const CountryWrapper = styled(Stack).attrs({
  size: 'full',
})`
  + * > * {
    border-top: 1px solid ${({ theme }) => theme.separator};
  }
`;

const CollapseButton = styled(ButtonReset).attrs({ type: 'button' })`
  color: ${({ theme }) => theme.secondary};
`;

const Sublabel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
`;

const Regions = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
})``;

const Country = ({ country, name, id }) => {
  const { t } = useTranslation('misc');
  const [isExpanded, setIsExpanded] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const { currentUserRole } = useApp();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSelectCountry, setIsSelectCountry] = useState(values.countries[country?.id]);

  const formatAggregateValue = input => {
    if (country?.regions?.length === 0) {
      return false;
    }
    const countryRegionIds = (country?.regions || []).map(
      ({ id }) => id,
    );
    const filledCountryRegionIds = countryRegionIds.filter(id =>
      Boolean(input?.[id]),
    );
    if (filledCountryRegionIds.length !== countryRegionIds.length) {
      return false;
    }

    const countryValues = countryRegionIds.map(id => input?.[id]);

    if (
      countryValues.filter(value => Boolean(value)).length ===
      country?.regions?.length
    ) {
      return true;
    }

    return false;
  };

  const isAllChecked = formatAggregateValue(get(values, name));

  const handleToggleCountry = () => {
    const newFieldValue = (country.regions || []).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: !isAllChecked,
      }),
      {},
    );

    setFieldValue(name, { ...values?.[name], ...newFieldValue });
    if (country.regions.nodes?.length) {
      setFieldValue(`countries.${country?.id}`, !isAllChecked);      
    } else {
      setFieldValue(`countries.${country?.id}`, !isSelectCountry);
    }
  };

  const handleToggleRegion = id => {
    setFieldValue(`${name}.${id}`, !values?.regions?.[id]);
    setFieldValue(`countries.${country?.id}`, !(!!values?.regions?.[id]));
  };

  return (
    <CountryWrapper>
      <Regions>
        <CountryLine isChecked={isAllChecked}>
          <Stack gutterSize={0.5} alignY="center">
            <Stack alignY="center" style={{ height: 24 }}>
              <span
                className={`flag-icon flag-icon-${country?.alpha2?.toLowerCase()}`}
              />
            </Stack>

            <Text type="body" fontWeight="600">
              {country?.title} (
              {t('user:region', { count: country?.regions.length })})
            </Text>
          </Stack>

          <Stack alignY="center" gutterSize={0.75}>
            <CollapseButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? t('less') : t('more')}
            </CollapseButton>
            <Stack alignX="flex-end">
              <ButtonReset onClick={() => {
                  handleToggleCountry();
                  setIsSelectCountry(!isSelectCountry)
                }} type="button">
                {((isAllChecked || isSelectCountry)) ? (
                  <IcCheckMark size={24} color={theme.textLight} />
                ) : (
                  <IcAddCircleMulti size={24} color={theme.secondary} />
                )}
              </ButtonReset>
              {currentUserRole === 'to_admin' && (
                <ButtonReset
                  onClick={()=>{setModalOpen(true)}}
                  type="button"
                  style={{ marginLeft: 5 }}
                >
                  <IcMail size={24} color={theme.secondary} />
                </ButtonReset>
              )}
            </Stack>
          </Stack>
        </CountryLine>

        {isExpanded && (
          <Stack direction="column" size="full">
            {(country?.regions || []).map(region => (
              <RegionLine
                onClick={() => handleToggleRegion(region?.id)}
                style={{ width: '100%' }}
                type="button"
                isChecked={values?.regions?.[region.id]}
                key={region?.id}
              >
                <Stack
                  size="full"
                  alignX="space-between"
                  alignY="center"
                  paddingVertical={0.25}
                  paddingRight={1.25}
                  paddingLeft={2.5}
                >
                  <div>{region?.title}</div>
                  <Stack alignX="flex-end">
                    {values?.regions?.[region.id] ? (
                      <IcCheckMark size={24} color={theme.textLight} />
                    ) : (
                      <IcAddCircle size={24} color={theme.secondary} />
                    )}
                  </Stack>
                </Stack>
              </RegionLine>
            ))}
          </Stack>
        )}
      </Regions>
      {currentUserRole === 'to_admin' && (
        <OrganizationCountryBookingReceptionEmailsModal
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          countryId={id}
        />
      )}
    </CountryWrapper>
  );
};

const RegionsSelectorField = ({ label, sublabel, name }) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [countries, setCountries] = useState([]);

  const { data: countriesData, isLoading } = useAsync({
    promiseFn: getCountriesWithQuery,
    watch: debouncedSearch,
    search: debouncedSearch,
  });

  const { data: regionsData } = useAsync({
    promiseFn: getRegionsWithQuery,
    watch: debouncedSearch,
    search: debouncedSearch,
  });

  const regionList = regionsData?.data;
  const countryList = countriesData?.data;

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  useEffect(() => {
    const regionsCountries = sortBy(
      values(
        groupBy(
          regionList, (region) => region?.country?.id,
        )
      ))

    let countriesList = []

    if(debouncedSearch !== '')
    {
      keys(regionsCountries).map(id => {
        const regions = regionsCountries[id];
        const country = regions?.[0]?.country;
        countriesList.push({...country, regions: regions})
      });

      keys(countryList).map(id => {
        const country = countryList[id];

        if (!countriesList.find((elem) => elem.id === country.id)) {
          countriesList.push(country);
        } else {
          const index = countriesList.findIndex((elem) => elem.id === country.id);

          countriesList[index].regions = country.regions;
        }
      });

    }else{
      countriesList = countriesData?.data ?? [];
    }
    setCountries(countriesList.sort((elem, elem2) => elem.title.localeCompare(elem2.title)));

  }, [countriesData, regionsData])

  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      {Boolean(sublabel) && <Sublabel>{sublabel}</Sublabel>}

      <Stack direction="column" gutterSize={1}>
        <Search value={search} onChange={setSearch} />
        {isLoading &&
          <Container>
            <Spinner/>
          </Container>
        }
        {countries.length > 0 && (
          <Card>
            <Stack direction="column">
              {countries.map((country) => (
                <Country key={country?.id} country={country} name={name}  id={country?.id}/>
              ))}
            </Stack>
          </Card>
        )}
      </Stack>
    </FormField>
  );
};

export default RegionsSelectorField;
