export default function SearchCategory({ label, icon, isSelected, onSelect }) {
  return (
    <button
      onClick={(event) => {
        event.stopPropagation();
        onSelect();
      }}
      className={[
        'py-1 px-2.5 rounded-full border-0',
        'text-xs font-medium uppercase',
        isSelected ? 'bg-k-orange' : 'bg-gray-600/10'
      ].join(' ')}
    >
      {icon && (
        <i
          className={[
            "far mr-1.5",
            icon,
            isSelected ? "text-white" : "text-gray-600"
          ].join(" ")}
        />
      )}
      <span
        className={[
          isSelected ? "text-white" : "text-gray-500"
        ].join("")}
      >
        {label}
      </span>
    </button>
  );
}
