export const ProductTypeEnum = {
  EXPERIENCE: 'experience',
  HOTEL: 'hotel',
};

export const ProgramAccommodationTypeEnum = {
  NIGHT_TRANSFER: 'nightTransfer',
  NO_ACCOMMODATION: 'noAccomodation', // The typo is intentional to match the API
  FREE_ACCOMMODATION: 'freeAccomodation', // The typo is intentional to match the API
  HOTEL: 'hotel',
};

export const ExtraScopeEnum = {
  GLOBAL: 'global',
  DAY: 'day'
};

export const ExtraRequirementEnum = {
  REQUIRED: 'required',
  SUGGESTED: 'suggested',
};

export const OrderDirectionEnum = {
  DESC: 'desc',
  ASC: 'ASC',
};

export const OrderColumnEnum = {
  UPDATED_AT: 'updated_at',
};

export const ApiPollingStateEnum = {
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished'
};

export const RoleEnum = {
  KOOB_ADMIN: 'koob_admin',
  DMC_ADMIN: 'dmc_admin',
  DMC_USER: 'dmc_user',
  TO_ADMIN: 'to_admin',
  TO_USER: 'to_user',
};

export const AllotmentDayStateEnum = {
  ON_REQUEST: 'on_request',
  STOP_SALE: 'stop_sale',
  FREE_SALE: 'free_sale',
  ALLOTMENT: 'allotment',
};
