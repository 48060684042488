import KoobTitle from '../../../../components/Koob/KoobTitle';
import { Fragment, useState } from 'react';
import ReceiptHiddenSection from './ReceiptHiddenSection';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { useTranslation } from 'react-i18next';
import Editor from 'components/Fields/Editor';
import { ButtonReset } from '@koob/margaret';
import { REACT_QUILL_INIT_CONTENT, REACT_QUILL_EMPTY_CONTENT } from 'constants';
import DOMPurify from 'dompurify';

const isDefaultContent = value => {
  const defaultContents = [
    '<p><br></p>',
    '<h1><br></h1>',
    '<h2><br></h2>',
    '<h3><br></h3>',
    '<p></p>',
  ];

  return defaultContents.includes(value);
};

export default function ReceiptNotes() {
  const { t } = useTranslation('trips');
  const [additionalInformations, setAdditionalInformations] = useState([
    {
      id: crypto.randomUUID(),
      title: t('receipt.notes.defaultTitle'),
      notes: '',
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const activeAdditionalInfos = additionalInformations[activeIndex];

  const addNewAdditionalInformation = () => {
    setAdditionalInformations(prev => {
      return [
        ...prev,
        {
          id: crypto.randomUUID(),
          title: t('receipt.notes.new'),
          notes: '',
        },
      ];
    });
    setActiveIndex(additionalInformations.length);
  };

  const updateActiveAdditionalInformation = (key, value) => {
    setAdditionalInformations(prev =>
      prev.map((info, index) =>
        index === activeIndex ? { ...info, [key]: value } : info,
      ),
    );
  };

  const removeAdditionalInformation = idToRemove => {
    const updatedAdditionalInformations = additionalInformations.filter(
      ({ id }) => id !== idToRemove,
    );
    setAdditionalInformations(updatedAdditionalInformations);
  };

  const handleActiveIndexChange = newIndex => {
    setActiveIndex(newIndex);
  };

  return (
    <>
      {additionalInformations.map(({ id, title, notes }) => (
        <div
          key={id}
          className={[
            'w-full',
            (isDefaultContent(notes) || notes.length === 0) ? 'export-hidden' : ''
          ].join(' ')}
        >
          <KoobTitle>
            {(isDefaultContent(notes) || notes.length === 0) && (
              <i className="fa fa-eye-slash mr-1.5" />
            )}
            {title}
          </KoobTitle>
          <div className="my-5 text-gray-500">
            <div
              className="prose prose-sm w-full max-w-full"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notes) }}
            />
          </div>
        </div>
      ))}

      <ReceiptHiddenSection>
        <KoobTitle size="md">
          <div className="flex space-x-3 items-center">
            <div>{t('receipt.notes.title')}</div>
            <KoobBadge>{t('receipt.optional')}</KoobBadge>
          </div>
        </KoobTitle>
        <div className="flex flex-wrap space-x-2 my-2">
          {additionalInformations.map(({ id, title }, index) => (
            <button
              key={id}
              className={[
                'border rounded-md shadow-xl p-2 font-medium',
                index === activeIndex
                  ? 'bg-orange-100 border-orange-500 text-orange-500'
                  : 'border-gray-300',
              ].join(' ')}
              type="button"
              onClick={event => {
                event.stopPropagation();
                handleActiveIndexChange(index);
              }}
            >
              <span>{title}</span>
              {index !== 0 && (
                <ButtonReset
                  style={{
                    marginLeft: '0.5rem',
                  }}
                  onClick={event => {
                    event.stopPropagation();
                    if (activeIndex >= index && activeIndex > 0) {
                      setActiveIndex(activeIndex - 1);
                    }
                    removeAdditionalInformation(id);
                  }}
                >
                  <i className="fa-solid fa-trash-can" />
                </ButtonReset>
              )}
            </button>
          ))}
          <ButtonReset
            className="bg-orange-500"
            onClick={() => addNewAdditionalInformation()}
          >
            <i className="fa-light fa-circle-plus text-base" />
          </ButtonReset>
        </div>
        <KoobParagraph size="sm">
          {t('receipt.notes.description1')}
        </KoobParagraph>

        <Fragment key={activeIndex}>
          <input
            value={activeAdditionalInfos?.title}
            onChange={e =>
              updateActiveAdditionalInformation('title', e.target.value)
            }
            className="mt-3 w-full bg-white border border-gray-300 rounded-md p-2"
            placeholder="e.g. Additional Information"
          />
          <div className="mt-1 w-full bg-white border border-gray-300 rounded-md p-2">
            <Editor
              value={activeAdditionalInfos?.notes}
              onChange={value => {
                const newValue =
                  value === REACT_QUILL_INIT_CONTENT ||
                  value === REACT_QUILL_EMPTY_CONTENT
                    ? ''
                    : value;
                return updateActiveAdditionalInformation('notes', newValue);
              }}
              placeholder="e.g. Please note that the hotel does not accept pets."
              enchanced
            />
          </div>
        </Fragment>
      </ReceiptHiddenSection>
    </>
  );
}
