export const SEARCH_TYPES = {
  HOTEL: 'hotel',
  EXPERIENCE: 'experience',
  ACTIVITY: 'activity',
  PROGRAM: 'program',
  TRANSFER: 'transfer',
  CITY: 'city',
  REGION: 'region',
  COUNTRY: 'country',
}

export const SEARCH_TYPE_ICONS = {
  [SEARCH_TYPES.HOTEL]: 'fa-bed',
  [SEARCH_TYPES.EXPERIENCE]: 'fa-layer-group',
  [SEARCH_TYPES.ACTIVITY]: 'fa-layer-group',
  [SEARCH_TYPES.PROGRAM]: 'fa-calendar-image',
  [SEARCH_TYPES.TRANSFER]: 'fa-exchange-alt',
  [SEARCH_TYPES.CITY]: 'fa-map-marker-alt',
  [SEARCH_TYPES.REGION]: 'fa-map',
  [SEARCH_TYPES.COUNTRY]: 'fa-flag',
}
