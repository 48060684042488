import ReceiptHiddenSection from './ReceiptHiddenSection';
import KoobButton from '../../../../components/Koob/KoobButton';
import { Form, Formik } from 'formik';
import { DateRangePicker, TextField } from '../../../../components/Fields';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils';
import { useState } from 'react';

export default function ReceiptContentManualItem({ setManualItems, currency }) {
  const { t } = useTranslation('trips');

  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');

  const addManualItem = (values, { resetForm }) => {
    const price = parseFloat(values.price)
    setManualItems(prev => [
      ...prev,
      {
        ...values,
        id: crypto.randomUUID(),
        type: 'manual',
        startAt,
        endAt,
        priceWithoutMarkup: price,
        price,
        currency
      }
    ]);
    resetForm();
    setStartAt('');
    setEndAt('');
  };

  return (
    <ReceiptHiddenSection className="mt-3">
      <KoobTitle size="md">
        <div className="flex space-x-3 items-center">
          <div>
            {t('receipt.manualItems.title')}
          </div>
          <KoobBadge>
            {t('receipt.optional')}
          </KoobBadge>
        </div>
      </KoobTitle>

      <KoobParagraph size="sm">
        {t('receipt.manualItems.description')}
      </KoobParagraph>

      <div className="my-3">
        <Formik
          initialValues={{
            name: '',
            pax: '',
            price: '',
          }}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = t('misc:required');
            }

            if (!values.price) {
              errors.price = t('misc:required');
            }

            return errors;
          }}
          validateOnChange={true}
          onSubmit={addManualItem}
        >
          {({ isValid }) => (
            <Form>
              <div className="flex space-x-3 items-end">
                <TextField
                  label={t('receipt.manualItems.itemName')}
                  name="name"
                  placeholder="e.g. Airport transfer"
                  required
                />

                <div>
                  <div className="mb-1 font-medium">
                    {t('receipt.bookedItems.columnDates')}
                  </div>
                  <DateRangePicker
                    wrapperStyle={{
                      width: 300,
                      '--range-picker-width': `300px`,
                    }}
                    value={[startAt, endAt]}
                    isClearable={true}
                    onChange={value => {
                      setStartAt(formatDate(value?.[0], 'yyyy-MM-dd'));
                      setEndAt(formatDate(value?.[1], 'yyyy-MM-dd'));
                    }}
                  />
                </div>

                <div className="w-20">
                  <TextField
                    label={t('receipt.bookedItems.columnPax')}
                    name="pax"
                    type="number"
                    min="0"
                  />
                </div>

                <div className="w-36">
                  <TextField
                    label={`${t('receipt.bookedItems.columnTotal')} (${currency?.toUpperCase()})`}
                    name="price"
                    type="number"
                    step="0.01"
                    min="0"
                    required
                  />
                </div>

                <KoobButton
                  type="submit"
                  disabled={!isValid}
                >
                  <i className="fa fa-plus mr-1" />
                  {t('receipt.manualItems.addItem')}
                </KoobButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ReceiptHiddenSection>
  )
}
