import { post } from './';
import axios from "axios";
import { API_RAILS_BASE, CLIENT_ID } from "../constants";

export const login = payload =>
  post(`/api/v1/oauth/token`, {
    ...payload,
  });

export const refreshToken = () =>
  post(`/api/v1/oauth/token`, {
    refreshToken: localStorage.getItem('refreshToken'),
  });

export const signUp = payload =>
  post(`/api/v1/auth/signup`, {
    ...payload,
  });

export const revokeToken = async (impersonating = false) => {
  await axios.post(`${API_RAILS_BASE}/api/v1/oauth/revoke`, {
    token: impersonating ? localStorage.getItem('impersonationToken') : localStorage.getItem('accessToken'),
    client_id: CLIENT_ID,
  })
}

export const tokenIntrospection = async () => {
  const response = (await axios.post(`${API_RAILS_BASE}/api/v1/oauth/introspect`, {
    token: localStorage.getItem('accessToken'),
    client_id: CLIENT_ID,
  })).data
  return Boolean(response.active);
}
